import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export default function SearchHelmet({ query }) {
  const { t } = useTranslation("site");

  return (
    <Helmet>
      <title>{t("helmet.search.title", { query })}</title>

      <meta
        name="twitter:title"
        content={t("helmet.search.title", { query })}
      />

      <meta property="og:title" content={t("helmet.search.title", { query })} />

      <meta property="og:image" content={t("helmet.search.image")} />
      <link rel="image_src" href={t("helmet.search.image")} />
      <meta name="twitter:image" content={t("helmet.search.image")} />
      <meta property="vk:image" content={t("helmet.search.image")} />
    </Helmet>
  );
}

SearchHelmet.propTypes = {
  query: PropTypes.string,
};
