import React from "react";
import PropTypes from "prop-types";
import ButtonBase from "@mui/material/ButtonBase";
import Logo from "site/components/Logo";
import { styled } from "@mui/material/styles";

const DesktopButton = styled(ButtonBase)({
  display: "flex",
  marginRight: "auto",
  height: 48,
  borderRadius: 4,
  "&:hover": {
    textDecoration: "none",
  },
  "& svg": {
    display: "block",
    userSelect: "none",
  },
});

export default function HeaderLogo({ onClick }) {
  return (
    <DesktopButton href="/" onClick={onClick} aria-label="Logo">
      <Logo height={40} />
    </DesktopButton>
  );
}

HeaderLogo.propTypes = {
  onClick: PropTypes.func,
};
