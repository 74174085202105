import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Logo from "site/components/Logo";
import { Helmet } from "react-helmet-async";

const PREFIX = "PageBody";

const classes = {
  card: `${PREFIX}-card`,
  title: `${PREFIX}-title`,
  buttonText: `${PREFIX}-buttonText`,
  logo: `${PREFIX}-logo`,
};

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",

  [`& .${classes.card}`]: {
    margin: 0,
    width: "100%",
    maxWidth: 840,
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(2),
    },
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.buttonText}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.logo}`]: {
    height: 32,
    width: 160,
    margin: " auto",
  },
}));

export default function PageBody({ page }) {
  return (
    <Root>
      <Helmet>
        <title>{page.title}</title>
      </Helmet>

      <Card className={classes.card}>
        <AppBar position="static" color="default" elevation={2}>
          <Toolbar>
            <div className={classes.logo}>
              <Logo />
            </div>
          </Toolbar>
        </AppBar>

        <CardContent>
          <Typography variant="h5" gutterBottom>
            {page.title}
          </Typography>

          <Typography
            component="div"
            className="help-page"
            dangerouslySetInnerHTML={{ __html: page.text }}
          />
        </CardContent>
      </Card>
    </Root>
  );
}
PageBody.propTypes = {
  page: PropTypes.shape({
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
  }),
};
