import React from "react";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";

const StyledButton = styled(IconButton)({
  marginLeft: "auto",
  color: "rgba(255, 255, 255, 0.3)",
});

export default function HeaderSearchButton({ onClick }) {
  const { t } = useTranslation("site");

  return (
    <Tooltip title={t("search")}>
      <StyledButton onClick={onClick} size="large" color="inherit" edge="end">
        <SearchIcon />
      </StyledButton>
    </Tooltip>
  );
}

HeaderSearchButton.propTypes = {
  onClick: PropTypes.func,
};
