import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Logo from "site/components/Logo";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import HistoryButton from "site/components/HistoryButton";
import PrintButton from "site/components/PrintButton";

const PREFIX = "DocumentBody";

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  title: `${PREFIX}-title`,
  loading: `${PREFIX}-loading`,
  buttonText: `${PREFIX}-buttonText`,
  logo: `${PREFIX}-logo`,
};

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",

  [`& .${classes.card}`]: {
    margin: 0,
    width: "100%",
    maxWidth: 840,
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(2),
    },
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.loading}`]: {
    position: "fixed",
    left: "50%",
    top: "50%",
    marginTop: -20,
    marginLeft: -20,
  },

  [`& .${classes.buttonText}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.logo}`]: {
    height: 32,
    width: 160,
    marginRight: " auto",
  },
}));

const RU = "ru";

function getDocument({ version, date, country }) {
  if (!date) return null;
  if (!version) return null;

  if (country === RU && version.titleRu && version.textRu) {
    return {
      title: version.titleRu,
      text: version.textRu,
    };
  }

  return {
    title: version.titleEn,
    text: version.textEn,
  };
}

export default function DocumentBody({ document, country, ott }) {
  const { t } = useTranslation("site");

  const [date, setDate] = useState(document.date);

  const versions = document.versions.map((v) => v.date);
  const version = document.versions.find((v) => v.date === date);

  const doc = getDocument({ version, date, country });

  let contentRef = null;

  return (
    <Root>
      <Helmet>
        <title>{doc.title}</title>
      </Helmet>

      <Card className={classes.card}>
        <AppBar position="static" color="default" elevation={0}>
          <Toolbar>
            <div className={classes.logo}>
              <Logo ott={ott} />
            </div>

            {versions.length > 1 && (
              <HistoryButton
                versions={versions}
                date={date}
                onChange={setDate}
              />
            )}

            <PrintButton content={() => contentRef} />
          </Toolbar>
        </AppBar>

        <CardContent
          ref={(el) => {
            contentRef = el;
          }}
        >
          <Typography variant="h5" gutterBottom>
            {doc.title}
          </Typography>

          <Typography variant="body1" color="textSecondary">
            {t("document.version", { date })}
          </Typography>

          <Typography
            component="div"
            className="help-page"
            dangerouslySetInnerHTML={{ __html: doc.text }}
          />
        </CardContent>
      </Card>
    </Root>
  );
}

DocumentBody.propTypes = {
  ott: PropTypes.bool,
  country: PropTypes.string.isRequired,
  document: PropTypes.shape({
    id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    viewerCountry: PropTypes.string.isRequired,
    versions: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        titleRu: PropTypes.string,
        titleEn: PropTypes.string,
      })
    ),
  }),
};
