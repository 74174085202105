import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import { truncate } from "core/utils/string";
import { Link } from "react-router-dom";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import getContentMeta from "site/utils/getContentMeta";
import { useTranslation } from "react-i18next";
import contentUrl from "core/utils/contentUrl";
import Thumbnail from "core/components/Thumbnail";
import ThumbnailProgress from "core/components/ThumbnailProgress";

const PREFIX = "ContentListItem";

const classes = {
  avatar: `${PREFIX}-avatar`,
  mediaLink: `${PREFIX}-mediaLink`,
  body: `${PREFIX}-body`,
  cardHeader: `${PREFIX}-cardHeader`,
  link: `${PREFIX}-link`,
  notAvailable: `${PREFIX}-notAvailable`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.avatar}`]: {
    backgroundColor: "#151515",
  },

  [`& .${classes.mediaLink}`]: {
    position: "relative",
    display: "block",
    color: "inherit",
    [theme.breakpoints.up("sm")]: {
      transition: "transform .15s ease-in-out",
      transform: "scale(1)",
      "&:hover": {
        transform: "scale(1.03)",
      },
    },
  },

  [`& .${classes.body}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },

  [`& .${classes.cardHeader}`]: {
    width: "100%",
    alignItems: "flex-start",
    padding: "16px 0",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },

  [`& .${classes.link}`]: {
    color: "inherit",
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.mode === "light" ? "black" : "white",
    },
  },

  [`& .${classes.notAvailable}`]: {
    cursor: "auto",
    pointerEvents: "none",
    userSelect: "none",
    [theme.breakpoints.up("sm")]: {
      "&:hover": {
        transform: "scale(1)",
      },
    },
  },
}));

export default function ContentListItem({
  start,
  finish,
  content,
  play,
  className,
}) {
  const { t } = useTranslation("site");

  const { isAvailableOnSportrecs = true } = content;

  const href = contentUrl(content.shareUrl, { start, finish });
  const props = { target: "_blank", href };

  return (
    <Root className={className}>
      <a
        {...props}
        className={classNames(classes.mediaLink, {
          [classes.notAvailable]: !isAvailableOnSportrecs,
        })}
      >
        <Thumbnail
          content={content}
          play={play}
          start={start}
          finish={finish}
        />

        <ThumbnailProgress content={content} />
      </a>

      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Link to={`/${content.rightholder.slug}`}>
            {content.rightholder.logo ? (
              <Avatar
                src={content.rightholder.logo.url}
                className={classes.avatar}
                alt={content.title}
              />
            ) : (
              <Avatar className={classes.avatar}>
                {content.rightholder.name[0]}
              </Avatar>
            )}
          </Link>
        }
        title={truncate(content.title, 65)}
        titleTypographyProps={{
          variant: "subtitle2",
          className: classNames(classes.link, {
            [classes.notAvailable]: !isAvailableOnSportrecs,
          }),
          gutterBottom: true,
          component: "a",
          href,
          target: "_blank",
        }}
        subheader={
          <Fragment>
            <Typography variant="body2" display="block" color="textSecondary">
              <Link
                to={`/${content.rightholder.slug}`}
                className={classes.link}
              >
                {content.rightholder.name}
              </Link>
              {content.rightholder.sport && " • "}
              {content.rightholder.sport?.title}
            </Typography>
            <Typography variant="body2" display="block" color="textSecondary">
              {getContentMeta(t, content)}
            </Typography>
          </Fragment>
        }
      />
    </Root>
  );
}

ContentListItem.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    premium: PropTypes.bool,
    thumbnails: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
      })
    ).isRequired,
    title: PropTypes.string.isRequired,
    access: PropTypes.shape({
      token: PropTypes.string,
      hostnames: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    shareUrl: PropTypes.string.isRequired,
    siteUrl: PropTypes.string.isRequired,
    startAt: PropTypes.string,
    duration: PropTypes.number,
    rightholder: PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      logo: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
      sport: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
    }).isRequired,
    isAvailableOnSportrecs: PropTypes.bool,
  }),
  play: PropTypes.bool,
  className: PropTypes.string,
  start: PropTypes.number,
  finish: PropTypes.number,
};
