import React, { useState } from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import copy from "copy-to-clipboard";
import ShareDialog from "site/components/ShareDialog";
import ShareIcon from "@mui/icons-material/Share";
import getUserAgent from "core/utils/getUserAgent";

function ShareButton({
  enqueueSnackbar,
  shareText,
  // shareDescription,
  shareImage,
  shareUrl,
  shareUrlParams,
  embedCode,
  // className,
  // active,
  size,
  variant,
  currentTime,
  setStartTime,
  setStartFrom,
  startFrom,
  startTime,
}) {
  const { t } = useTranslation("site");

  const [anchorEl, setAnchorEl] = useState(null);

  const userAgent = process.browser && getUserAgent();

  const handleClick = async (event) => {
    if (navigator.share && userAgent?.userDeviceType !== "Web") {
      const shareData = {
        title: shareText,
        // text: shareDescription, // BUG in IOS https://adactio.com/journal/15972
        url: shareUrl,
      };

      try {
        await navigator.share(shareData);
      } catch (err) {
        console.log(err);
      }
    } else {
      setAnchorEl(event.target);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onClickCopyEmbed = () => {
    copy(embedCode);
    enqueueSnackbar(t("shareButton.codeCopied"), {
      variant: "success",
    });
  };

  const onClickCopyUrl = (url) => {
    copy(url);
    enqueueSnackbar(t("shareButton.urlCopied"), {
      variant: "success",
    });
  };

  return (
    <>
      <Tooltip title={t("shareButton.title")} arrow>
        <Button
          onClick={handleClick}
          startIcon={<ShareIcon color="inherit" />}
          color="inherit"
          size={size}
          variant={variant}
        >
          {t("shareButton.title")}
        </Button>
      </Tooltip>

      <ShareDialog
        currentTime={currentTime}
        setStartTime={setStartTime}
        setStartFrom={setStartFrom}
        startFrom={startFrom}
        startTime={startTime}
        open={open}
        onClose={handleClose}
        onClickCopyEmbed={onClickCopyEmbed}
        onClickCopyUrl={onClickCopyUrl}
        embedCode={embedCode}
        shareUrl={shareUrl}
        shareUrlParams={shareUrlParams}
        shareText={shareText}
        shareImage={shareImage}
      />
    </>
  );
}

ShareButton.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  shareDescription: PropTypes.string,
  shareText: PropTypes.string.isRequired,
  shareUrl: PropTypes.string.isRequired,
  shareUrlParams: PropTypes.object,
  embedCode: PropTypes.string,
  shareImage: PropTypes.string,
  className: PropTypes.string,
  active: PropTypes.bool,
  size: PropTypes.oneOf(["small", "large"]),
  variant: PropTypes.oneOf(["outlined", "contained"]),

  currentTime: PropTypes.number,
  setStartTime: PropTypes.func,
  setStartFrom: PropTypes.func,
  startFrom: PropTypes.bool,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default withSnackbar(ShareButton);
