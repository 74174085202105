import React from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import Tooltip from "@mui/material/Tooltip";
import ReactToPrint from "react-to-print";
import { useTranslation } from "react-i18next";

export default function PrintButton({ content }) {
  const { t } = useTranslation("site");

  return (
    <ReactToPrint
      pageStyle="@page { size: auto;  margin: 1em; } @media print { body { -webkit-print-color-adjust: exact; font-family: Helvetica; } }"
      copyStyles={false}
      trigger={() => (
        <span>
          <Tooltip title={t("document.print")}>
            <IconButton edge="end" size="large">
              <PrintIcon />
            </IconButton>
          </Tooltip>
        </span>
      )}
      content={content}
    />
  );
}

PrintButton.propTypes = {
  content: PropTypes.func,
};
