import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

export default function TagHelmet({ rightholder, tag }) {
  const title = `${tag.title} – ${rightholder.name}`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="twitter:title" content={title} />
      <meta property="og:title" content={title} />
    </Helmet>
  );
}

TagHelmet.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  rightholder: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    slug: PropTypes.string.isRequired,
    logo: PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
