import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { ContentsQuery } from "site/queries";
import ContentCarousel from "site/components/ContentCarousel";

const STREAM = "STREAM";

export default function ContentStreamList({
  limit,
  title,
  streamStatus,
  rightholderCountryCode,
  rightholderSlug,
  //   moreUrl,
  sportSlug,
  sportId,
}) {
  const { data, error } = useQuery(ContentsQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      type: STREAM,
      page: 0,
      rowsPerPage: limit,
      streamStatus,
      rightholderCountryCode,
      rightholderSlug,
      sportSlug,
      sportId,
    },
  });

  if (error) return null;

  const nodes = data?.contents?.nodes;

  if (!nodes || nodes.length === 0) {
    return null;
  }

  return (
    <>
      {title}
      <ContentCarousel nodes={nodes} />
    </>
  );
}

ContentStreamList.propTypes = {
  limit: PropTypes.number,
  title: PropTypes.node,
  moreUrl: PropTypes.string,
  streamStatus: PropTypes.string,
  rightholderSlug: PropTypes.string,
  sportSlug: PropTypes.string,
  sportId: PropTypes.string,
  rightholderCountryCode: PropTypes.string,
};
