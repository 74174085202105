import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import embed from "core/utils/embed";
import moment from "core/utils/moment";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import ShareButton from "site/components/ShareButton";
import Skeleton from "@mui/material/Skeleton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import classNames from "classnames";
import { textFormat } from "core/utils/string";
import ChannelButton from "site/components/ChannelButton";
import { truncate } from "core/utils/string";
import TagList from "site/components/TagList";
import contentUrl from "core/utils/contentUrl";

const PREFIX = "ContentBody";

const classes = {
  root: `${PREFIX}-root`,
  tagsSection: `${PREFIX}-tagsSection`,
  contentSection: `${PREFIX}-contentSection`,
  actionSection: `${PREFIX}-actionSection`,
  buttons: `${PREFIX}-buttons`,
  channelSection: `${PREFIX}-channelSection`,
  title: `${PREFIX}-title`,
  titleLarge: `${PREFIX}-titleLarge`,
  description: `${PREFIX}-description`,
  label: `${PREFIX}-label`,
  value: `${PREFIX}-value`,
  metadata: `${PREFIX}-metadata`,
  expand: `${PREFIX}-expand`,
  expandOpen: `${PREFIX}-expandOpen`,
  titleButton: `${PREFIX}-titleButton`,
  meta: `${PREFIX}-meta`,
  subscribe: `${PREFIX}-subscribe`,
  share: `${PREFIX}-share`,
  sportLink: `${PREFIX}-sportLink`,
  player: `${PREFIX}-player`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.tagsSection}`]: {
    padding: theme.spacing(0, 2, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 0, 2),
    },
  },

  [`& .${classes.contentSection}`]: {
    padding: theme.spacing(2, 2, 1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 0, 1),
    },
  },

  [`& .${classes.actionSection}`]: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1, 0),
      flexDirection: "row",
    },
  },

  [`& .${classes.buttons}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },

  [`& .${classes.channelSection}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1, 0),
    },
  },

  [`& .${classes.title}`]: {
    fontSize: "1rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.2rem",
    },
  },

  [`& .${classes.titleLarge}`]: {
    fontSize: "2rem",
    fontWeight: 500,
  },

  [`& .${classes.description}`]: {
    marginTop: 16,

    "& a": {
      color: "#81d4fa",
    },
    "& p": {
      "&:first-of-type": {
        marginTop: 0,
      },
      "&:last-child": {
        marginBottom: 0,
      },
    },
  },

  [`& .${classes.label}`]: {
    width: 100,
    padding: "5px 0",
    borderBottom: 0,
  },

  [`& .${classes.value}`]: {
    padding: "5px 0",
    borderBottom: 0,
  },

  [`& .${classes.metadata}`]: {
    marginTop: 16,
  },

  [`& .${classes.expand}`]: {
    display: "inline-block",
    verticalAlign: "bottom",
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },

  [`& .${classes.expandOpen}`]: {
    transform: "rotate(180deg)",
  },

  [`& .${classes.titleButton}`]: {
    display: "block",
    width: "100%",
    textAlign: "left",
  },

  [`& .${classes.meta}`]: {
    marginRight: "auto",
    marginBottom: 10,
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },
  },

  [`& .${classes.subscribe}`]: {
    marginLeft: "auto",
  },

  [`& .${classes.share}`]: {
    marginLeft: "auto",
  },

  [`& .${classes.sportLink}`]: {
    color: "inherit",
    "&:hover": {
      color: "white",
    },
  },

  [`& .${classes.player}`]: {
    [theme.breakpoints.up("sm")]: {
      borderRadius: 4,
    },
  },
}));

const COLLECTION = "COLLECTION";
const STREAM = "STREAM";
const VIDEO = "VIDEO";
const FINISHED = "FINISHED";

function getMetadata(t, content) {
  const metadata = [];

  if (content.type === VIDEO) {
    metadata.push(t("totalViews", { count: content.totalViews }));
  }

  if (content.type === STREAM) {
    if (content.streamData?.status === FINISHED) {
      metadata.push(t("totalViews", { count: content.totalViews }));
    } else {
      metadata.push(t("viewersOnline", { count: content.viewersOnline }));
    }
  }

  metadata.push(moment(content.publishAt).format("lll"));

  return metadata;
}

export default function ContentBody({
  content,
  loading,
  start,
  finish,
  currentTime,
  titleSize,
}) {
  const { t } = useTranslation("site");

  const [descriptionOpen, setDescriptionOpen] = useState(false);

  const [startTime, setStartTime] = useState("");
  const [startFrom, setStartFrom] = useState(false);

  if (loading) {
    return (
      <Root>
        <div className={classes.contentSection}>
          <Skeleton
            variant="rectangular"
            style={{ width: "100%", height: 36, marginBottom: 8 }}
          />
        </div>
        <div className={classes.actionSection}>
          <Skeleton variant="rectangular" style={{ width: 200, height: 20 }} />
        </div>
      </Root>
    );
  }

  if (!content) return null; // На всякий случай

  const metadata = getMetadata(t, content);

  const title = (
    <Typography
      variant="body1"
      color="textPrimary"
      component="h1"
      align="left"
      display="inline"
      className={classNames(classes.title, {
        [classes.titleLarge]: titleSize === "large",
      })}
      gutterBottom={!!content.description}
    >
      {content.title}
    </Typography>
  );

  const toggleDescription = () => {
    setDescriptionOpen((v) => !v);
  };

  const MAX_LENGTH = 200;
  const description = content.description || "";
  const truncated = description.length > MAX_LENGTH;
  const __html = textFormat(
    descriptionOpen
      ? description
      : truncate(description.replace("\n\n", " "), MAX_LENGTH)
  );

  const embedUrl = contentUrl(content.url, {
    start: startFrom ? startTime : start,
    finish,
    autoplay: 1,
    loop: 1,
  });

  const embedCode =
    content.type !== COLLECTION &&
    embed(embedUrl, {
      responsive: true,
    });

  const contentShareUrl = content.shareUrl;

  const shareUrl = contentUrl(contentShareUrl, {
    finish,
    start: startFrom ? startTime : start,
  });

  return (
    <Root>
      {content.tags.length > 0 && (
        <div className={classes.tagsSection}>
          <TagList
            tags={content.tags}
            link={(tag) => `/${content.rightholder.slug}/tags/${tag.id}`}
          />
        </div>
      )}

      <div className={classes.contentSection}>
        {truncated ? (
          <ButtonBase
            onClick={toggleDescription}
            className={classes.titleButton}
            component="span"
          >
            {title}

            <ArrowDropDownIcon
              className={classNames(classes.expand, {
                [classes.expandOpen]: descriptionOpen,
              })}
            />
          </ButtonBase>
        ) : (
          title
        )}

        {description && (
          <Typography
            className={classes.description}
            component="div"
            variant="body2"
            color="textSecondary"
            dangerouslySetInnerHTML={{ __html }}
          />
        )}
      </div>

      <div className={classes.actionSection}>
        <Typography
          color="textSecondary"
          className={classes.meta}
          variant="body2"
        >
          {content?.sport?.title}
          {content.sport && " • "}
          {metadata.join(" • ")}
        </Typography>
        <div className={classes.buttons}>
          <ShareButton
            shareUrlParams={{ contentId: content.id }}
            shareUrl={shareUrl}
            embedCode={embedCode}
            startTime={startTime}
            setStartTime={setStartTime}
            setStartFrom={setStartFrom}
            currentTime={currentTime}
            startFrom={startFrom}
            shareText={content.title}
            shareDescription={content.rightholder.name}
            shareImage={content.preview}
            className={classes.share}
          />
        </div>
      </div>

      <Divider />

      <div className={classes.channelSection}>
        <ChannelButton rightholder={content.rightholder} />
      </div>
    </Root>
  );
}

ContentBody.propTypes = {
  loading: PropTypes.bool,
  titleSize: PropTypes.oneOf(["large"]),
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    siteUrl: PropTypes.string.isRequired,
    shareUrl: PropTypes.string.isRequired,
    preview: PropTypes.string,
    publishAt: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    type: PropTypes.oneOf([VIDEO, STREAM, COLLECTION]),
    totalViews: PropTypes.number,
    viewersOnline: PropTypes.number,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
    sport: PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
    rightholder: PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }),
  start: PropTypes.number,
  finish: PropTypes.number,
  currentTime: PropTypes.number,
};
