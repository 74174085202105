import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Sports from "./Sports";
import Rightholders from "./Rightholders";
import moment from "core/utils/moment";
import ContentList from "site/components/ContentList";
import ContentStreamList from "site/components/ContentStreamList";
import HomepageTitle from "site/components/HomepageTitle";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const VIDEO = "VIDEO";
const COLLECTION = "COLLECTION";
const STARTED = "STARTED";
const PENDING = "PENDING";
const FINISHED = "FINISHED";

const Root = styled("div")(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 2),
  },
}));

export default function Feed() {
  const { t } = useTranslation("site");
  const [dateFrom] = useState(moment().subtract(1, "week").format());
  const [dateTo] = useState(moment().format());
  const [sportId, setSportId] = useState();

  const key = [dateFrom, dateTo, sportId].join("#");

  return (
    <>
      <Stack divider={<Divider />} spacing={0}>
        <Sports
          dateFrom={dateFrom}
          dateTo={dateTo}
          onClickSport={setSportId}
          sportId={sportId}
        />
        <Rightholders dateFrom={dateFrom} dateTo={dateTo} sportId={sportId} />
      </Stack>

      <Root key={key}>
        <ContentStreamList
          title={<HomepageTitle title={t("streams_live_now")} />}
          limit={24}
          sportId={sportId}
          streamStatus={STARTED}
        />

        <ContentStreamList
          title={<HomepageTitle title={t("streams_upcoming")} />}
          limit={24}
          sportId={sportId}
          streamStatus={PENDING}
        />

        <ContentStreamList
          title={<HomepageTitle title={t("streams_recent")} />}
          limit={24}
          sportId={sportId}
          streamStatus={FINISHED}
        />

        <ContentList
          title={<HomepageTitle title={t("latest_sport_videos")} />}
          rowsPerPage={12}
          sportId={sportId}
          types={[VIDEO, COLLECTION]}
        />
      </Root>
    </>
  );
}
