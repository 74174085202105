import React, { useState, useCallback } from "react";
import { styled } from "@mui/material/styles";
import ContentList from "site/components/ContentList";
import ContentPlayer from "site/components/ContentPlayer";
import ContentBody from "site/components/ContentBody";
import ErrorScreen from "core/components/ErrorScreen";
import useScrollToTop from "core/hooks/useScrollToTop";
import { ContentQuery } from "site/queries";
import { useQuery } from "@apollo/client";
import ReactGA from "react-ga4";
import { createEvent, setToken } from "core/utils/tracker";
import { Navigate, useParams, useLocation } from "react-router-dom";
import NoMatchScreen from "site/components/NoMatchScreen";
import ContentHelmet from "site/components/ContentHelmet";
import SponsorTopBanner from "site/components/SponsorTopBanner";
import usePreviewBanner from "core/hooks/usePreviewBanner";

const VIDEO = "VIDEO";
const VIDEO_ON_SITE = "VIDEO_ON_SITE";
const SPONSOR_TOP_BANNER_PREVIEW_URL =
  "https://st.spads.me/image/uev1Db0mX.png";

const PREFIX = "Video";

const classes = {
  root: `${PREFIX}-root`,
  top: `${PREFIX}-top`,
  player: `${PREFIX}-player`,
  bottom: `${PREFIX}-bottom`,
  body: `${PREFIX}-body`,
  recommended: `${PREFIX}-recommended`,
  recommendedFixed: `${PREFIX}-recommendedFixed`,
  rootWide: `${PREFIX}-rootWide`,
};

const Root = styled("div")(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.top}`]: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },

  [`& .${classes.player}`]: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      marginRight: "345px",
    },
  },

  [`& .${classes.bottom}`]: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },

  [`& .${classes.body}`]: {
    width: "100%",
  },

  [`& .${classes.recommended}`]: {
    flexShrink: 0,
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      width: "345px",
    },
  },

  [`& .${classes.recommendedFixed}`]: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      right: 0,
      top: 0,
      bottom: 0,
      padding: theme.spacing(2, 2),
      width: "361px",
      overflowX: "hidden",
      overflowY: "auto",

      msOverflowStyle: "none", // IE 10+
      scrollbarWidth: "none", // Firefox
      "&::-webkit-scrollbar": {
        display: "none", // Safari and Chrome
      },
    },
  },

  [`& .${classes.rootWide}`]: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0),
    },
    [`& .${classes.bottom}`]: {
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(0, 2, 2),
      },
    },
    [`& .${classes.player}`]: {
      [theme.breakpoints.up("md")]: {
        marginRight: 0,
      },
    },
    [`& .${classes.recommendedFixed}`]: {
      position: "static",
      padding: theme.spacing(0),
      width: "100%",
    },
  },
}));

export default function Video() {
  const location = useLocation();
  const { contentId } = useParams();
  const previewBanner = usePreviewBanner(
    "sponsorTopBannerPreview",
    SPONSOR_TOP_BANNER_PREVIEW_URL
  );

  useScrollToTop();

  const onCompleted = (data) => {
    if (data.content) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: data.content.title,
      });

      setToken(data.content.access.token);
      createEvent("page", "load");
    }
  };

  const searchParams = new URLSearchParams(location.search);

  const start = searchParams.has("start")
    ? parseInt(searchParams.get("start"), 10)
    : null;

  const finish = searchParams.has("finish")
    ? parseInt(searchParams.get("finish"), 10)
    : null;

  const { data, loading, error } = useQuery(ContentQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      contentId,
    },
    onCompleted,
  });

  const [currentTime, setCurrentTime] = useState(0);

  const onCurrentTimeUpdate = useCallback(
    (e) => setCurrentTime(Math.ceil(e.currentTime)),
    []
  );

  const onSeekEnd = useCallback((value) => {
    setCurrentTime(Math.ceil(value));
  }, []);

  const onUpdate = useCallback((e) => {
    setCurrentTime(Math.ceil(e.currentTime));
  }, []);

  const content = data?.content;

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!content && !loading) {
    return <NoMatchScreen />;
  }

  if (content && content.type !== VIDEO) {
    return (
      <Navigate
        to={content.siteUrl.replace("video", content.type.toLowerCase())}
      />
    );
  }

  return (
    <>
      {content && (
        <SponsorTopBanner
          contentId={contentId}
          rightholderId={content.rightholder.id}
          place={VIDEO}
          previewBanner={previewBanner}
        />
      )}
      <Root>
        <ContentHelmet content={content} />

        <div className={classes.top}>
          <div className={classes.player} key={contentId}>
            <ContentPlayer
              sponsorPlayerLogoPlace={VIDEO_ON_SITE}
              sponsorPlayerButtonPlace={VIDEO_ON_SITE}
              sponsorVideoPlace={VIDEO_ON_SITE}
              sponsorInStreamBannerPlace={VIDEO_ON_SITE}
              sponsorCornerBannerPlace={VIDEO_ON_SITE}
              hideTitle
              autoPlay
              content={content}
              loading={loading}
              onSeeked={onCurrentTimeUpdate}
              onTimeUpdate={onCurrentTimeUpdate}
              onSeekEnd={onSeekEnd}
              onUpdate={onUpdate}
              start={start}
              finish={finish}
            />
          </div>
        </div>

        <div className={classes.bottom}>
          <div className={classes.body} key={contentId}>
            <ContentBody
              content={content}
              loading={loading}
              start={start}
              finish={finish}
              currentTime={currentTime}
            />
          </div>
          <div className={classes.recommended}>
            <div className={classes.recommendedFixed}>
              <ContentList
                skip={!process.browser}
                relatedContentId={contentId}
                xs={12}
                sm={6}
                md={12}
                lg={12}
                xl={12}
                rowsPerPage={6}
              />
            </div>
          </div>
        </div>
      </Root>
    </>
  );
}
