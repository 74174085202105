import React from "react";
import { styled } from "@mui/material/styles";
import { useLocation, useParams } from "react-router-dom";
import HomepageTitle from "site/components/HomepageTitle";
import ChannelHeader from "site/components/ChannelHeader";
import ContentList from "site/components/ContentList";
import TagHelmet from "site/components/TagHelmet";
import ChannelBanner from "site/components/ChannelBanner";
import useScrollToTop from "core/hooks/useScrollToTop";
import ReactGA from "react-ga4";
import { TagQuery } from "site/queries";
import { useQuery } from "@apollo/client";
import NoMatchScreen from "site/components/NoMatchScreen";
import ErrorScreen from "core/components/ErrorScreen";

const Root = styled("div")(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 2),
  },
}));

export default function Tag() {
  const { slug, id } = useParams();
  const location = useLocation();

  const onCompleted = (data) => {
    if (data.tag) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: data.tag.title,
      });
    }
  };

  const { data, loading, error } = useQuery(TagQuery, {
    fetchPolicy: "cache-and-network",
    variables: { slug, id },
    onCompleted,
  });

  useScrollToTop();

  const rightholder = data?.rightholder;
  const tag = data?.tag;

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!loading) {
    if (!rightholder) {
      return <NoMatchScreen />;
    }

    if (!tag) {
      return <NoMatchScreen />;
    }
  }

  const title = tag?.title;

  return (
    <>
      {rightholder && <TagHelmet rightholder={rightholder} tag={tag} />}

      {rightholder && <ChannelBanner rightholder={rightholder} />}

      <ChannelHeader key={slug} loading={loading} rightholder={rightholder} />

      <Root>
        {title && <HomepageTitle title={title} />}

        <ContentList rightholderSlug={slug} tagId={id} rowsPerPage={12} />
      </Root>
    </>
  );
}
