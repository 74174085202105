import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Zoom from "@mui/material/Zoom";
import Fab from "@mui/material/Fab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const PREFIX = "ContentCarouselPrevButton";

const classes = {
  prev: `${PREFIX}-prev`,
  btnRoot: `${PREFIX}-btnRoot`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.prev}`]: {
    position: "absolute",
    left: 0,
    top: 8,
    bottom: 0,
    zIndex: 1,
    // background: "#ffff0066",
    width: "100%",
    height: 0,
    paddingTop: "56.25%",
    pointerEvents: "none",
    "& button": {
      pointerEvents: "all",
      position: "absolute",
      left: 0,
      top: "calc(50% - 20px)",
    },
    [theme.breakpoints.up("sm")]: {
      width: "calc(50% - 12px)",
      paddingTop: "27%",
    },
    [theme.breakpoints.up("md")]: {
      width: "calc(33.333333% - 16px)",
      paddingTop: "17.8%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "calc(25% - 18px)",
      paddingTop: "13.3%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "calc(16.666667% - 20px)",
      paddingTop: "8.8%",
    },
  },

  [`& .${classes.btnRoot}`]: {
    backgroundColor: theme.palette.mode === "dark" ? "#1f1f1f" : "#fff",
    color:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, 0.85)"
        : "rgba(0, 0, 0, 0.65)",
    "&:hover": {
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
      backgroundColor: theme.palette.mode === "dark" ? "#1f1f1f" : "#fff",
    },
  },
}));

export default function ContentCarouselPrevButton({ disabled, onClick }) {
  return (
    <Root className={classes.prev}>
      <Zoom in={!disabled}>
        <Fab size="small" classes={{ root: classes.btnRoot }} onClick={onClick}>
          <ArrowBackIcon color="inherit" />
        </Fab>
      </Zoom>
    </Root>
  );
}

ContentCarouselPrevButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
