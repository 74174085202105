import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import LoadMore from "core/components/LoadMore";
import ErrorScreen from "core/components/ErrorScreen";
import { ContentsQuery } from "site/queries";
import { useQuery } from "@apollo/client";
import ReactGA from "react-ga4";
import ContentListItem from "site/components/ContentListItem";
import Previewable from "site/components/Previewable";
import { useMediaQuery } from "@mui/material";
// import ContentCarouselSkeleton from "site/components/ContentCarouselSkeleton";

const COLLECTION = "COLLECTION";
const STREAM = "STREAM";
const VIDEO = "VIDEO";

export default function ContentList({
  tagId,
  type,
  types,
  rowsPerPage,
  sportSlug,
  sportId,
  streamStatus,
  rightholderSlug,
  rightholderCountryCode,
  relatedContentId,
  productId,
  pollInterval,
  search,
  xs,
  sm,
  md,
  lg,
  xl,
  skip,
  title,
  empty,
  onCompleted,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { data, loading, error, fetchMore, refetch } = useQuery(ContentsQuery, {
    fetchPolicy: "cache-and-network",
    skip,
    variables: {
      tagId,
      type,
      types,
      search,
      sportSlug,
      sportId,
      rightholderSlug,
      rightholderCountryCode,
      relatedContentId,
      productId,
      rowsPerPage,
      streamStatus,
    },
    onCompleted,
  });

  useEffect(() => {
    if (pollInterval) {
      if (process.browser) {
        const interval = setInterval(() => refetch(), pollInterval);
        return () => clearInterval(interval);
      }
    }
  }, [pollInterval, refetch]);

  if (error) return <ErrorScreen error={error} />;

  const nodes = data?.contents?.nodes || [];
  const count = data?.contents?.count || 0;
  const hasMore = nodes.length < count;
  const showLoadMore = hasMore && !loading;
  const nextPage = Math.ceil(nodes.length / rowsPerPage);

  if (!nodes || nodes.length === 0) {
    return empty;
  }

  const onLoadMore = () => {
    ReactGA.event({
      category: "User",
      action: "Load more contents",
      label: nextPage + " page",
    });

    fetchMore({
      variables: {
        page: nextPage,
      },
    });
  };

  return (
    <>
      {title}

      <Grid container spacing={2} data-testid="ContentList">
        {nodes.map((content) => (
          <Grid
            item
            xs={xs}
            sm={sm}
            md={md}
            lg={lg}
            xl={xl}
            key={content.id}
            data-testid="ContentListItem"
          >
            <Previewable isMobile={isMobile}>
              {(focused) => (
                <ContentListItem content={content} play={focused} />
              )}
            </Previewable>
          </Grid>
        ))}
      </Grid>

      {showLoadMore && <LoadMore key={nextPage} onLoadMore={onLoadMore} />}
    </>
  );
}

ContentList.propTypes = {
  title: PropTypes.node,
  xs: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  md: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xl: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  type: PropTypes.oneOf([STREAM, VIDEO, COLLECTION]),
  types: PropTypes.arrayOf(PropTypes.oneOf([STREAM, VIDEO, COLLECTION])),
  rowsPerPage: PropTypes.number,
  search: PropTypes.string,
  sportSlug: PropTypes.string,
  sportId: PropTypes.string,
  tagId: PropTypes.string,
  productId: PropTypes.string,
  rightholderSlug: PropTypes.string,
  rightholderCountryCode: PropTypes.string,
  relatedContentId: PropTypes.string,
  pollInterval: PropTypes.number,
  skip: PropTypes.bool,
  empty: PropTypes.node,
  onCompleted: PropTypes.func,
  streamStatus: PropTypes.string,
};

ContentList.defaultProps = {
  empty: null,
  fetchPolicy: "cache-and-network",
  rowsPerPage: 10,
  xs: 12,
  sm: 6,
  md: 4,
  lg: 3,
  xl: 2,
};
