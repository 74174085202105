import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Thumbnail from "core/components/Thumbnail";
import { Link } from "react-router-dom";

const PREFIX = "RecommendedContentsListItem";

const classes = {
  avatar: `${PREFIX}-avatar`,
  primary: `${PREFIX}-primary`,
  item: `${PREFIX}-item`,
};

const StyledListItem = styled(ListItem)({
  [`& .${classes.avatar}`]: {
    width: 100,
    marginTop: 5,
    marginBottom: 5,
    marginRight: 16,
  },
  [`& .${classes.primary}`]: {
    fontWeight: 500,
  },
  [`&.${classes.item}`]: {
    paddingTop: 10,
    paddingBottom: 10,
  },
});

function RecommendedContentsListItem({ selected, content, onClickItem }) {
  const onClick = (event) => {
    event.preventDefault();
    onClickItem(content);
  };

  return (
    <StyledListItem
      dense
      selected={selected}
      button
      component={Link}
      to={content.siteUrl}
      alignItems="flex-start"
      onClick={onClick}
      className={classes.item}
    >
      <ListItemAvatar className={classes.avatar}>
        <Thumbnail content={content} small />
      </ListItemAvatar>

      <ListItemText
        primary={content.title}
        primaryTypographyProps={{
          color: "textPrimary",
          className: classes.primary,
        }}
      />
    </StyledListItem>
  );
}

RecommendedContentsListItem.propTypes = {
  onClickItem: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    siteUrl: PropTypes.string.isRequired,
  }),
};

export default RecommendedContentsListItem;
