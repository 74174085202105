import React from "react";
import { styled } from "@mui/material/styles";
import ContentList from "site/components/ContentList";
import ContentBody from "site/components/ContentBody";
import ErrorScreen from "core/components/ErrorScreen";
import useScrollToTop from "core/hooks/useScrollToTop";
import { ContentQuery } from "site/queries";
import { useQuery } from "@apollo/client";
import ReactGA from "react-ga4";
import { createEvent } from "core/utils/tracker";
import Grid from "@mui/material/Grid";
import { Navigate, useLocation, useParams } from "react-router-dom";
import NoMatchScreen from "site/components/NoMatchScreen";
import ContentHelmet from "site/components/ContentHelmet";
import SponsorTopBanner from "site/components/SponsorTopBanner";
import usePreviewBanner from "core/hooks/usePreviewBanner";

const Root = styled("div")(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(2, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(2),
  },
}));

const COLLECTION = "COLLECTION";
const SPONSOR_TOP_BANNER_PREVIEW_URL =
  "https://st.spads.me/image/uev1Db0mX.png";

export default function Collection() {
  const location = useLocation();
  const { contentId } = useParams();
  const previewBanner = usePreviewBanner(
    "sponsorTopBannerPreview",
    SPONSOR_TOP_BANNER_PREVIEW_URL
  );

  useScrollToTop();

  const onCompleted = (data) => {
    if (data.content) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: data.content.title,
      });

      createEvent("page", "load");
    }
  };

  const { data, loading, error } = useQuery(ContentQuery, {
    // fetchPolicy: "cache-and-network",
    variables: { contentId },
    onCompleted,
  });

  const content = data?.content;

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!content && !loading) {
    return <NoMatchScreen />;
  }

  if (content && content.type !== COLLECTION) {
    return (
      <Navigate
        to={content.siteUrl.replace("collection", content.type.toLowerCase())}
      />
    );
  }

  return (
    <>
      {content && (
        <SponsorTopBanner
          contentId={contentId}
          rightholderId={content.rightholder.id}
          place={COLLECTION}
          previewBanner={previewBanner}
        />
      )}

      <ContentHelmet content={content} />

      <Root key={contentId}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ContentBody
              content={content}
              loading={loading}
              titleSize="large"
            />
          </Grid>
          <Grid item xs={12}>
            <ContentList
              relatedContentId={contentId}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={2}
              rowsPerPage={12}
            />
          </Grid>
        </Grid>
      </Root>
    </>
  );
}
