import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Toolbar from "@mui/material/Toolbar";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import ShareIcons from "site/components/ShareIcons";
import VideoTimeCheckbox from "core/components/VideoTimeCheckbox";

const PREFIX = "ShareDialog";

const classes = {
  title: `${PREFIX}-title`,
  content: `${PREFIX}-content`,
  skeleton: `${PREFIX}-skeleton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.content}`]: {
    padding: theme.spacing(2),
    overflow: "hidden",
  },

  [`& .${classes.skeleton}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShareDialog({
  open,
  onClose,
  onClickCopyUrl,
  shareUrl,
  shareText,
  shareImage,
  currentTime,
  setStartTime,
  setStartFrom,
  startFrom,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation("site");

  let targetShareUrl = shareUrl;

  return (
    <StyledDialog
      fullWidth
      disableScrollLock
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      maxWidth="sm"
      open={open}
      onClose={onClose}
      scroll="body"
    >
      <AppBar position="static" color="default">
        <Toolbar>
          <Typography variant="h5" className={classes.title}>
            {t("shareButton.title")}
          </Typography>
          <Tooltip title={t("close")} arrow>
            <IconButton onClick={onClose} edge="end" size="large">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      <DialogContent className={classes.content}>
        <Grid
          container
          spacing={fullScreen ? 3 : 4}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <ShareIcons
              colors
              small={fullScreen}
              shareUrl={targetShareUrl}
              shareText={shareText}
              shareImage={shareImage}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={targetShareUrl}
              variant="outlined"
              label={t("shareButton.shareUrl")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      color="inherit"
                      onClick={() => onClickCopyUrl(targetShareUrl)}
                    >
                      {t("shareButton.copy")}
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {!!currentTime && (
            <Grid item xs={12}>
              <VideoTimeCheckbox
                setStartTime={setStartTime}
                setStartFrom={setStartFrom}
                startFrom={startFrom}
                currentTime={currentTime}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
}

ShareDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onClickCopyUrl: PropTypes.func.isRequired,
  onClickCopyEmbed: PropTypes.func,
  shareUrl: PropTypes.string.isRequired,
  shareText: PropTypes.string.isRequired,
  shareImage: PropTypes.string,
  shareUrlParams: PropTypes.object,
  embedCode: PropTypes.string,
  currentTime: PropTypes.number,
  setStartTime: PropTypes.func,
  setStartFrom: PropTypes.func,
  startFrom: PropTypes.bool,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
