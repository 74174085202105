import React from "react";
import PropTypes from "prop-types";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import Stack from "@mui/material/Stack";
import Chip from "site/components/Chip";
import { styled } from "@mui/material/styles";

export const SportsQuery = loader("./SportsQuery.graphql");

const Root = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-start",
  overflowX: "auto",
  overflowY: "hidden",
  msOverflowStyle: "none", // IE 10+
  scrollbarWidth: "none", // Firefox
  "&::-webkit-scrollbar": {
    display: "none", // Safari and Chrome
  },
});

export default function Sports({ dateFrom, dateTo, sportId, onClickSport }) {
  const { data } = useQuery(SportsQuery, {
    variables: {
      limit: 100,
      sortType: "LAST_PUBLISH_AT",
      dateFrom,
      dateTo,
    },
  });

  const nodes = data?.topSports?.nodes;

  if (!nodes) return null;

  return (
    <Root>
      <Stack
        direction="row"
        spacing={1}
        // sx={{ width: "100%", justifyContent: "center" }}
      >
        {nodes.map((sport) => (
          <Chip
            key={sport.id}
            label={sport.title}
            selected={sport.id === sportId}
            onClick={() => onClickSport(sport.id)}
          />
        ))}
      </Stack>
    </Root>
  );
}

Sports.propTypes = {
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  sportId: PropTypes.string,
  onClickSport: PropTypes.func.isRequired,
};
