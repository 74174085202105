import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import RightholderLogoCarouselItem from "site/components/RightholderLogoCarouselItem";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Zoom from "@mui/material/Zoom";
import useCarousel from "core/hooks/useCarousel";

const PREFIX = "RightholderLogoCarousel";

const classes = {
  root: `${PREFIX}-root`,
  scrollable: `${PREFIX}-scrollable`,
  item: `${PREFIX}-item`,
  prev: `${PREFIX}-prev`,
  next: `${PREFIX}-next`,
  btnRoot: `${PREFIX}-btnRoot`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: "relative",
  },

  [`& .${classes.scrollable}`]: {
    padding: 16,

    overflowY: "hidden",
    overflowX: "auto",

    msOverflowStyle: "none", // IE 10+
    scrollbarWidth: "none", // Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Safari and Chrome
    },
  },

  [`& .${classes.item}`]: {},

  [`& .${classes.prev}`]: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    // background: "#ffff0066",
    width: 0,
    height: "100%",
    pointerEvents: "none",
    "& button": {
      pointerEvents: "all",
      position: "absolute",
      left: 0,
      top: "calc(50% - 20px)",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.next}`]: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    // background: "#ffff0066",
    width: 0,
    height: "100%",
    pointerEvents: "none",
    "& button": {
      pointerEvents: "all",
      position: "absolute",
      right: 0,
      top: "calc(50% - 20px)",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.btnRoot}`]: {
    backgroundColor: "#1f1f1f",
    color: "rgba(255, 255, 255, 0.3)",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#1f1f1f",
    },
  },
}));

export default function RightholderLogoCarousel({ nodes }) {
  const { rootRef, onScroll, prev, next, onClickNext, onClickPrev } =
    useCarousel();

  if (!nodes || !nodes.length) return null;

  return (
    <Root className={classes.root}>
      <div className={classes.scrollable} ref={rootRef} onScroll={onScroll}>
        <Grid container spacing={2} wrap="nowrap">
          {nodes.map((rightholder) => (
            <Grid item key={rightholder.id} className={classes.item}>
              <RightholderLogoCarouselItem rightholder={rightholder} />
            </Grid>
          ))}
        </Grid>
      </div>

      <div className={classes.prev}>
        <Zoom in={prev}>
          <Fab
            size="small"
            classes={{ root: classes.btnRoot }}
            onClick={onClickPrev}
            disabled={!prev}
          >
            <ChevronLeftIcon />
          </Fab>
        </Zoom>
      </div>
      <div className={classes.next}>
        <Zoom in={next}>
          <Fab
            size="small"
            classes={{ root: classes.btnRoot }}
            onClick={onClickNext}
            disabled={!next}
          >
            <ChevronRightIcon />
          </Fab>
        </Zoom>
      </div>
    </Root>
  );
}

RightholderLogoCarousel.defaultProps = {
  nodes: [],
};

RightholderLogoCarousel.propTypes = {
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
};
