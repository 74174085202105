import React from "react";
import PropTypes from "prop-types";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
import { Routes, Route, Outlet } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme } from "core/utils/theme";
import NoMatchScreen from "site/components/NoMatchScreen";
import Video from "site/components/Video";
import Collection from "site/components/Collection";
import Channel from "site/components/Channel";
import CookieBanner from "site/components/CookieBanner";
import Document from "site/components/Document";
import Page from "site/components/Page";
import Search from "site/components/Search";
import Explore from "site/components/Explore";
import Tag from "site/components/Tag";
import DebugAdv from "site/components/DebugAdv";
import AppShell from "site/components/AppShell";
import { Helmet } from "react-helmet-async";
// import ReactGA from "react-ga4";
import RetargetPixels from "core/components/RetargetPixels";
import CastProvider from "core/components/CastProvider";

// ReactGA.initialize("UA-00000000-0"); // если надо будет GA, то сюда вставить ID счетчика

const anchorOrigin = {
  vertical: "top",
  horizontal: "center",
};

const autoHideDuration = 2000;

const maxSnack = 1;

const theme = createTheme();

function Layout() {
  return (
    <AppShell>
      <Outlet />
    </AppShell>
  );
}

export default function App({ host }) {
  const ott = host.includes(process.env.RAZZLE_SPRINTTY_BASE_DOMAIN);
  const titleTemplate = "%s - SportRussia.tv";

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Helmet titleTemplate={titleTemplate} defaultTitle="Explore" />
      <SnackbarProvider
        anchorOrigin={anchorOrigin}
        autoHideDuration={autoHideDuration}
        maxSnack={maxSnack}
      >
        <CastProvider>
          <Routes>
            <Route
              path="/document/:slug/*"
              element={<Document host={host} />}
            />
            <Route path="/page/:slug" element={<Page />} />
            <Route path="/debug/adv" element={<DebugAdv />} />

            <Route element={<Layout />}>
              <Route path="/video/:contentId" element={<Video />} />
              <Route path="/collection/:contentId" element={<Collection />} />
              <Route path="/search" element={<Search />} />
              <Route path="/:slug/tags/:id" element={<Tag />} />
              <Route path="/:slug" element={<Channel />} />
              <Route path="/" element={<Explore />} />

              <Route path="*" element={<NoMatchScreen />} />
            </Route>
          </Routes>
        </CastProvider>
        <RetargetPixels eventType="showSite" />
        {!ott && <CookieBanner />}
      </SnackbarProvider>
    </ThemeProvider>
  );
}

App.propTypes = {
  host: PropTypes.string.isRequired,
};
