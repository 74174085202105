import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import ReactGA from "react-ga4";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Video from "core/components/Video";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import { InView } from "react-intersection-observer";
import { styled } from "@mui/material/styles";

const StyledVideo = styled(Video)({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const StyledInView = styled(InView)({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  width: "100%",
  height: "100%",
});

const StyledClose = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: 12,
  right: 12,
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

const DEFAULT = "DEFAULT";
const MEDIUM = "MEDIUM";
const HIGH = "HIGH";
const MAXRES = "MAXRES";

export default function ChannelBanner({ rightholder }) {
  const { t } = useTranslation("site");

  const [collapsed, setCollapsed] = useState(false);
  const [isVideoVisible, setVideoVisible] = useState(false);
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  let banner = null;

  if (rightholder.banner.length) {
    if (xl) {
      banner = rightholder.banner.find((b) => b.format === MAXRES);
    } else if (lg) {
      banner = rightholder.banner.find((b) => b.format === HIGH);
    } else if (md) {
      banner = rightholder.banner.find((b) => b.format === MEDIUM);
    }

    if (!banner) {
      banner = rightholder.banner.find((b) => b.format === DEFAULT);
    }
  }

  if (!banner && !rightholder.videoBanner) return null;

  const style = {};

  if (banner) {
    style["backgroundImage"] = `url('${banner.url}')`;
  }

  const onCanPlay = () => {
    setVideoVisible(true);
  };

  const onClick = () => {
    setCollapsed((v) => !v);

    ReactGA.event({
      category: "User",
      action: "Click to close icon on channel banner",
    });
  };

  return (
    <Collapse in={!collapsed}>
      <Box
        sx={{
          overflow: "hidden",
          position: "relative",
          width: "100%",
          height: 0,
          paddingTop: "25%",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            width: "100%",
            height: "100%",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          style={style}
        />

        {rightholder.videoBanner && (
          <StyledVideo
            onCanPlay={onCanPlay}
            hlsUrl={rightholder.videoBanner}
            autoPlay
            loop
            muted
          >
            {(videoEl, videoState, videoActions) => (
              <Fade in={isVideoVisible} timeout={1500}>
                <StyledInView
                  as="div"
                  onChange={(inView) => {
                    if (inView) {
                      videoActions.play();
                    } else {
                      videoActions.pause();
                    }
                  }}
                >
                  {videoEl}
                </StyledInView>
              </Fade>
            )}
          </StyledVideo>
        )}

        <Tooltip title={t("close")}>
          <StyledClose onClick={onClick}>
            <CloseIcon />
          </StyledClose>
        </Tooltip>
      </Box>
    </Collapse>
  );
}

ChannelBanner.propTypes = {
  rightholder: PropTypes.shape({
    videoBanner: PropTypes.string,
    banner: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        format: PropTypes.oneOf([DEFAULT, MEDIUM, HIGH, MAXRES]).isRequired,
      })
    ),
  }),
};
