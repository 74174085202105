import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { RightholderQuery } from "site/queries";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const Root = styled("div")(({ theme }) => ({
  overflow: "hidden",
  margin: 0,
  padding: theme.spacing(6, 2, 2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(6, 0, 2),
  },
}));

export default function ChannelTitle({ slug, title }) {
  const { t } = useTranslation("site");

  const { data = {}, loading } = useQuery(RightholderQuery, {
    fetchPolict: "cache-only",
    variables: {
      slug,
    },
  });

  if (loading) {
    return (
      <Root>
        <Skeleton variant="text" width={150} height={60} />
      </Root>
    );
  }

  return (
    <Root>
      <Typography variant="h5" component="h2" noWrap>
        {t(title, { title: data.rightholder.name })}
      </Typography>
    </Root>
  );
}

ChannelTitle.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
