import { loader } from "graphql.macro";

export const ContentQuery = loader("./ContentQuery.graphql");
export const ContentsQuery = loader("./ContentsQuery.graphql");
export const DocumentQuery = loader("./DocumentQuery.graphql");
export const FeaturedContentsQuery = loader("./FeaturedContentsQuery.graphql");
export const PageQuery = loader("./PageQuery.graphql");
export const RightholderParentContentsQuery = loader(
  "./RightholderParentContentsQuery.graphql"
);
export const RightholderQuery = loader("./RightholderQuery.graphql");
export const SearchSuggestQuery = loader("./SearchSuggestQuery.graphql");
export const SponsorTopBannerQuery = loader("./SponsorTopBannerQuery.graphql");
export const TagQuery = loader("./TagQuery.graphql");
export const TopContentsQuery = loader("./TopContentsQuery.graphql");
export const TrendingContentsQuery = loader("./TrendingContentsQuery.graphql");
