export default function jsonLdBreadcrumbList(content) {
  return JSON.stringify(
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: content.rightholder.name,
          item: `https://sportrussia.tv/${content.rightholder.slug}`,
        },
        {
          "@type": "ListItem",
          position: 2,
          name: content.title,
          item: content.shareUrl,
        },
      ],
    },
    null,
    2
  );
}
