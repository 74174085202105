import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { TrendingContentsQuery } from "site/queries";
import ErrorScreen from "core/components/ErrorScreen";
import ContentCarousel from "site/components/ContentCarousel";
import ContentCarouselSkeleton from "site/components/ContentCarouselSkeleton";

const VIDEO = "VIDEO";
const STREAM = "STREAM";
const COLLECTION = "COLLECTION";

export default function TrendingContentList({
  title,
  type,
  limit,
  sportSlug,
  rightholderSlug,
  countryCode,
}) {
  const { data, loading, error } = useQuery(TrendingContentsQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      countryCode,
      sportSlug,
      rightholderSlug,
      limit,
      type,
    },
  });

  if (error) return <ErrorScreen error={error} />;

  if (loading && !data) {
    return (
      <Fragment>
        {title}
        <ContentCarouselSkeleton />
      </Fragment>
    );
  }

  const nodes = data?.trendingContents?.nodes;

  if (!nodes || nodes.length === 0) {
    return null;
  }

  return (
    <Fragment>
      {title}
      <ContentCarousel nodes={nodes} />
    </Fragment>
  );
}

TrendingContentList.propTypes = {
  title: PropTypes.node,
  type: PropTypes.oneOf([STREAM, VIDEO, COLLECTION]),
  limit: PropTypes.number,
  search: PropTypes.string,
  sportSlug: PropTypes.string,
  rightholderSlug: PropTypes.string,
  countryCode: PropTypes.string,
};

TrendingContentList.defaultProps = {
  limit: 12,
};
