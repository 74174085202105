import React, { useState } from "react";
import HeaderLogo from "site/components/HeaderLogo";
import HeaderSearch from "site/components/HeaderSearch";
import HeaderSearchButton from "site/components/HeaderSearchButton";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

export default function Header() {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const [searchOpen, setSearchOpen] = useState(false);

  const onClickLogo = () => {
    ReactGA.event({
      category: "Header",
      action: "Click to logo",
    });
    window.scrollTo(0, 0);
  };

  const onClickSearchIcon = () => {
    ReactGA.event({
      category: "Header",
      action: "Open Search",
    });
    setSearchOpen(true);
  };

  const onClickCloseIcon = () => {
    searchParams.delete("query");
    // history.push(`/search?${searchParams}`);
    setSearchOpen(false);
  };

  return (
    <AppBar
      color="default"
      position="fixed"
      sx={{ backgroundColor: "#191919" }}
    >
      <Toolbar>
        {searchOpen ? (
          <HeaderSearch
            searchParams={searchParams}
            onClickCloseIcon={onClickCloseIcon}
          />
        ) : (
          <>
            <HeaderLogo onClick={onClickLogo} />
            <HeaderSearchButton onClick={onClickSearchIcon} />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
