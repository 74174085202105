import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { TopContentsQuery } from "site/queries";
import ErrorScreen from "core/components/ErrorScreen";
import ContentCarousel from "site/components/ContentCarousel";
import ContentCarouselSkeleton from "site/components/ContentCarouselSkeleton";

const RIGHTHOLDER_VIEWS_COUNT = "RIGHTHOLDER_VIEWS_COUNT";
const VIEWS_COUNT = "VIEWS_COUNT";
const STREAM = "STREAM";
const VIDEO = "VIDEO";
const COLLECTION = "COLLECTION";

export default function TopContentList({
  title,
  type,
  sortType,
  dateFrom,
  dateTo,
  limit,
  sportSlug,
  rightholderSlug,
  countryCode,
}) {
  const { data, loading, error } = useQuery(TopContentsQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      countryCode,
      sortType,
      dateFrom,
      dateTo,
      sportSlug,
      rightholderSlug,
      limit,
      type,
    },
  });

  if (error) return <ErrorScreen error={error} />;

  if (loading && !data) {
    return (
      <Fragment>
        {title}
        <ContentCarouselSkeleton />
      </Fragment>
    );
  }

  const nodes = data?.topContents?.nodes;

  if (!nodes || nodes.length === 0) {
    return null;
  }

  return (
    <Fragment>
      {title}
      <ContentCarousel nodes={nodes} />
    </Fragment>
  );
}

TopContentList.propTypes = {
  title: PropTypes.node,
  type: PropTypes.oneOf([STREAM, VIDEO, COLLECTION]),
  sortType: PropTypes.oneOf([RIGHTHOLDER_VIEWS_COUNT, VIEWS_COUNT]),
  limit: PropTypes.number,
  search: PropTypes.string,
  sportSlug: PropTypes.string,
  rightholderSlug: PropTypes.string,
  countryCode: PropTypes.string,
  dateFrom: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  dateTo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

TopContentList.defaultProps = {
  limit: 12,
  xs: 12,
  sm: 6,
  md: 4,
  lg: 3,
  xl: 2,
};
