import React from "react";
import { styled } from "@mui/material/styles";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import ErrorScreen from "core/components/ErrorScreen";
import { Helmet } from "react-helmet-async";

const Root = styled("div")({
  padding: 0,
  minHeight: "calc(90vh - 64px)",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export default function NoMatchScreen() {
  const { t } = useTranslation("site");

  return (
    <Root>
      <Helmet>
        <title>{t("noMatchScreen.title")}</title>
      </Helmet>
      <ErrorScreen
        icon={SentimentVeryDissatisfiedIcon}
        title={t("noMatchScreen.title")}
        text={t("noMatchScreen.text")}
        button={
          <Button disableElevation variant="contained" color="primary" href="/">
            {t("noMatchScreen.button")}
          </Button>
        }
      />
    </Root>
  );
}
