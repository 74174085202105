import "core/utils/polyfills";
import "core/utils/apm";
import "core/utils/i18n";

import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { hydrate, render } from "react-dom";
import { ApolloProvider } from "@apollo/client";
import { useSSR } from "react-i18next";
import { HelmetProvider } from "react-helmet-async";
import { client } from "core/utils/apollo";
import LoadingScreen from "core/components/LoadingScreen";
import App from "site/components/App";
import createEmotionCache from "core/utils/createEmotionCache";
import { CacheProvider } from "@emotion/react";

const cache = createEmotionCache();

const BaseApp = () => {
  useSSR(window.initialI18nStore, window.initialLanguage);

  return (
    <CacheProvider value={cache}>
      <HelmetProvider>
        <Suspense fallback={<LoadingScreen />}>
          <ApolloProvider client={client}>
            <BrowserRouter>
              <App host={document.location.hostname} />
            </BrowserRouter>
          </ApolloProvider>
        </Suspense>
      </HelmetProvider>
    </CacheProvider>
  );
};

const renderMethod = module.hot ? render : hydrate;

renderMethod(<BaseApp />, document.getElementById("root"));

if (module.hot) {
  module.hot.accept();
}
