import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";

const PREFIX = "TagList";

const classes = {
  root: `${PREFIX}-root`,
  label: `${PREFIX}-label`,
  chip: `${PREFIX}-chip`,
};

const Root = styled("div")({
  [`&.${classes.root}`]: {
    // outline: "1px solid yellow",
    overflow: "hidden",
  },
  [`& .${classes.label}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.chip}`]: {
    borderRadius: 4,
    color: "rgb(170, 170, 170)",
    textOverflow: "ellipsis",
    maxWidth: 260,
  },
});

export default function TagList({ tags, link, max }) {
  const { t } = useTranslation("site");

  const collapsible = tags.length > max;
  const [collapsed, setCollapsed] = useState(collapsible);

  const onClickMore = () => setCollapsed(false);

  const nodes = collapsed ? tags.slice(0, 3) : tags;

  return (
    <Root className={classes.root}>
      <Grid container spacing={1}>
        {nodes.map((tag) => (
          <Grid item key={tag.id}>
            <Chip
              size="small"
              variant="outlined"
              label={tag.title}
              clickable
              component={Link}
              to={link(tag)}
              classes={{
                root: classes.chip,
              }}
            />
          </Grid>
        ))}
        {collapsed && (
          <Grid item>
            <Tooltip title={t("more")}>
              <Chip
                label={<MoreVertIcon fontSize="small" />}
                size="small"
                variant="outlined"
                clickable
                onClick={onClickMore}
                classes={{
                  label: classes.label,
                  root: classes.chip,
                }}
              />
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Root>
  );
}

TagList.defaultProps = {
  max: 3,
};

TagList.propTypes = {
  max: PropTypes.number.isRequired,
  link: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
};
