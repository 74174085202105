import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorScreen from "core/components/ErrorScreen";
import { DocumentQuery } from "site/queries";
import NoMatchScreen from "site/components/NoMatchScreen";
import DocumentBody from "site/components/DocumentBody";
import { useParams, useMatch, Navigate } from "react-router-dom";

export default function Document({ host }) {
  const { slug } = useParams();
  const match = useMatch("/document/:slug/:country");

  const { data, loading, error } = useQuery(DocumentQuery, {
    variables: { slug },
  });

  const country = match?.params?.country;
  const document = data?.document;

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  if (!document && !loading) {
    return <NoMatchScreen />;
  }

  if (!country) {
    const country = document.viewerCountry === "RU" ? "ru" : "en";

    return <Navigate to={`/document/${slug}/${country}`} replace={true} />;
  }

  const ott = host.includes(process.env.RAZZLE_SPRINTTY_BASE_DOMAIN);

  return <DocumentBody document={document} country={country} ott={ott} />;
}

Document.propTypes = {
  host: PropTypes.string.isRequired,
};
