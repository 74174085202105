import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Player from "core/components/Player";
import Skeleton from "@mui/material/Skeleton";
import RetargetPixels from "core/components/RetargetPixels";

const PREFIX = "ContentPlayer";

const classes = {
  player: `${PREFIX}-player`,
  skeleton: `${PREFIX}-skeleton`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.player}`]: {
    zIndex: 1,
    position: "relative",
    height: 0,
    paddingTop: "75%",
    background: "black",
    overflow: "hidden",
    [smallScreen]: {
      paddingTop: "56.25%",
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: "56.25%",
      borderRadius: 4,
    },
    "&:fullscreen": {
      paddingTop: 0,
    },
  },

  [`& .${classes.skeleton}`]: {
    width: "100%",
    height: 0,
    paddingTop: "75%",
    [smallScreen]: {
      paddingTop: "56.25%",
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: "56.25%",
      borderRadius: 4,
    },
  },
}));

const smallScreen = "@media (min-width: 375px)";

export default function ContentPlayer({
  onChatAdv,
  muted,
  autoPlay,
  autoPause,
  hideTitle,
  content,
  loading,
  onEnded,
  start,
  finish,
  sponsorCornerBannerPlace,
  sponsorInStreamBannerPlace,
  sponsorPlayerLogoPlace,
  sponsorPlayerButtonPlace,
  sponsorVideoPlace,
  sponsorChatMessagePlace,
  onSeeked,
  onTimeUpdate,
  onSeekEnd,
  onUpdate,
}) {
  const [played, setPlayed] = useState(false);

  const onPlay = () => {
    setPlayed(true);
  };

  if (loading) {
    return <Skeleton variant="rectangular" className={classes.skeleton} />;
  }

  if (!content) return null; // На всякий случай

  const id = content.id + "-contentPlayer";

  return (
    <Root id={id} className={classes.player}>
      <Player
        onChatAdv={onChatAdv}
        onEnded={onEnded}
        onPlay={onPlay}
        autoPlay={autoPlay}
        autoPause={autoPause}
        content={content}
        hideTitle={hideTitle}
        key={content.id}
        onSeeked={onSeeked}
        onTimeUpdate={onTimeUpdate}
        onUpdate={onUpdate}
        onSeekEnd={onSeekEnd}
        hideRelatedContent
        hideShare
        hideLogo
        showAd
        muted={muted}
        fullscreenTarget={id}
        start={start}
        finish={finish}
        sponsorCornerBannerPlace={sponsorCornerBannerPlace}
        sponsorInStreamBannerPlace={sponsorInStreamBannerPlace}
        sponsorPlayerLogoPlace={sponsorPlayerLogoPlace}
        sponsorPlayerButtonPlace={sponsorPlayerButtonPlace}
        sponsorVideoPlace={sponsorVideoPlace}
        sponsorChatMessagePlace={sponsorChatMessagePlace}
      />

      <RetargetPixels
        rightholder={content.rightholder.id}
        viewSource="site"
        sport={content.sport?.id}
        content={content.id}
        contentType={content.type}
        eventType={played ? "playVideo" : "showPlayer"}
      />
    </Root>
  );
}

ContentPlayer.propTypes = {
  onChatAdv: PropTypes.func,
  muted: PropTypes.bool,
  autoPlay: PropTypes.bool,
  autoPause: PropTypes.bool,
  hideTitle: PropTypes.bool,
  onEnded: PropTypes.func,
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    premium: PropTypes.bool,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    rightholder: PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
    sport: PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string,
    }),
  }),
  loading: PropTypes.bool,
  start: PropTypes.number,
  finish: PropTypes.number,
  sponsorCornerBannerPlace: PropTypes.string,
  sponsorInStreamBannerPlace: PropTypes.string,
  sponsorPlayerLogoPlace: PropTypes.string,
  sponsorPlayerButtonPlace: PropTypes.string,
  sponsorVideoPlace: PropTypes.string,
  sponsorChatMessagePlace: PropTypes.string,
  onSeeked: PropTypes.func,
  onTimeUpdate: PropTypes.func,
  onUpdate: PropTypes.func,
  onSeekEnd: PropTypes.func,
};
