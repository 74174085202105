import React from "react";
import PropTypes from "prop-types";
import ButtonBase from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";

const Root = styled("div")(({ theme, selected }) => ({
  backgroundImage: selected
    ? `linear-gradient(180deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`
    : undefined,

  backgroundColor: selected ? undefined : "#4C4C4C",
  padding: "2px",
  borderRadius: "15px",
  display: "flex",
}));

const StyledButtonBase = styled(ButtonBase)(({ selected }) => ({
  backgroundColor: "#0D0D0D",
  borderRadius: "15px",
  padding: "5px 15px",
  color: selected ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.7)",
  fontWeight: 500,
  whiteSpace: "nowrap",
}));

export default function Chip({ label, selected, ...props }) {
  return (
    <Root selected={selected}>
      <StyledButtonBase selected={selected} {...props}>
        {label}
      </StyledButtonBase>
    </Root>
  );
}

Chip.propTypes = {
  label: PropTypes.node,
  selected: PropTypes.bool,
};
