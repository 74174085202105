import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

const DEFAULT = "DEFAULT";
const MEDIUM = "MEDIUM";
const HIGH = "HIGH";
const MAXRES = "MAXRES";

export default function BaseHelmet({
  title,
  description,
  keywords,
  canonicalUrl,
  rssUrl,
  logo,
  banner,
  image,
}) {
  if (!image) {
    if (!logo) {
      if (banner) {
        logo =
          banner.find((b) => b.format === MAXRES) ||
          banner.find((b) => b.format === HIGH) ||
          banner.find((b) => b.format === MEDIUM) ||
          banner.find((b) => b.format === DEFAULT);
      }
    }

    image = logo?.url;
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="twitter:title" content={title} />
      <meta property="og:title" content={title} />

      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />

      {keywords ? <meta name="keywords" content={keywords} /> : null}

      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Sportrussia.tv" />
      <meta name="twitter:card" content="summary_large_image" />

      {canonicalUrl ? <meta property="og:url" content={canonicalUrl} /> : null}

      {rssUrl ? (
        <link
          title={title}
          type="application/rss+xml"
          rel="alternate"
          href={rssUrl}
        />
      ) : null}
    </Helmet>
  );
}

BaseHelmet.propTypes = {
  canonicalUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  keywords: PropTypes.string,
  rssUrl: PropTypes.string,
  oembedUrl: PropTypes.string,
  image: PropTypes.string,
  logo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  banner: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
};
