import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export default function ExploreHelmet() {
  const { t } = useTranslation("site");

  return (
    <Helmet>
      <link
        title="Sport videos"
        type="application/rss+xml"
        rel="alternate"
        href="https://sportrussia.tv/rss/all.xml"
      />

      <title>{t("helmet.explore.title")}</title>

      <meta name="twitter:title" content={t("helmet.explore.title")} />
      <meta property="og:title" content={t("helmet.explore.title")} />

      <meta name="keywords" content={t("helmet.explore.keywords")} />

      <meta name="description" content={t("helmet.explore.description")} />

      <meta
        property="og:description"
        content={t("helmet.explore.description")}
      />
      <meta
        name="twitter:description"
        content={t("helmet.explore.description")}
      />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={`https://sportrussia.tv/explore`} />

      <meta property="og:site_name" content="sportrussia.tv" />
    </Helmet>
  );
}
