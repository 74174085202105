import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

const PREFIX = "ContentCarouselSkeleton";

const classes = {
  root: `${PREFIX}-root`,
  contentItem: `${PREFIX}-contentItem`,
  gridItem: `${PREFIX}-gridItem`,
  media: `${PREFIX}-media`,
  title: `${PREFIX}-title`,
  meta: `${PREFIX}-meta`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {},

  [`& .${classes.contentItem}`]: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "flex-start",
    textAlign: "inherit",
    width: "100%",
  },

  [`& .${classes.gridItem}`]: {
    display: "none",

    // Первый эллемент показывается всегда
    "&:nth-of-type(1)": {
      display: "block",
    },

    // Второй элемент показывается только на экранах выше sm
    "&:nth-of-type(2)": {
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },

    // Третий элемент показывается только на экранах выше md
    "&:nth-of-type(3)": {
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
    },

    // Четвертый элемент показывается только на экранах выше lg
    "&:nth-of-type(4)": {
      [theme.breakpoints.up("lg")]: {
        display: "block",
      },
    },

    // Пятый элемент показывается только на экранах выше xl
    "&:nth-of-type(5)": {
      [theme.breakpoints.up("xl")]: {
        display: "block",
      },
    },

    // Шестой элемент показывается только на экранах выше xl
    "&:nth-of-type(6)": {
      [theme.breakpoints.up("xl")]: {
        display: "block",
      },
    },
  },

  [`& .${classes.media}`]: {
    width: "100%",
    height: 0,
    paddingTop: "56.25%",
  },

  [`& .${classes.title}`]: {
    width: 240,
    height: 36,
    marginTop: 10,
  },

  [`& .${classes.meta}`]: {
    width: 190,
    height: 18,
    marginTop: 10,
  },
}));

export default function ContentCarouselSkeleton({ limit, xs, sm, md, lg, xl }) {
  return (
    <Root className={classes.root}>
      <Grid container spacing={2}>
        {Array.from({ length: limit }, (v, k) => (
          <Grid
            item
            xs={xs}
            sm={sm}
            md={md}
            lg={lg}
            xl={xl}
            key={k}
            className={classes.gridItem}
          >
            <div className={classes.contentItem}>
              <Skeleton variant="rectangular" className={classes.media} />

              <div className={classes.body}>
                <Skeleton variant="rectangular" className={classes.title} />
                <Skeleton variant="rectangular" className={classes.meta} />
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </Root>
  );
}

ContentCarouselSkeleton.defaultProps = {
  limit: 6,
  xs: 12,
  sm: 6,
  md: 4,
  lg: 3,
  xl: 2,
};

ContentCarouselSkeleton.propTypes = {
  limit: PropTypes.number,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
};
