import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import LocalStorage from "core/utils/localstorage";
import ReactGA from "react-ga4";
import NoSsr from "@mui/material/NoSsr";
import Container from "@mui/material/Container";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiLink from "@mui/material/Link";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const PREFIX = "CookieBanner";

const classes = {
  bannerCardContent: `${PREFIX}-bannerCardContent`,
  okButton: `${PREFIX}-okButton`,
  expand: `${PREFIX}-expand`,
  expandOpen: `${PREFIX}-expandOpen`,
  title: `${PREFIX}-title`,
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  right: 0,
  left: 0,
  top: "auto",
  backgroundColor: theme.palette.background.body,

  [`& .${classes.bannerCardContent}`]: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
  },

  [`& .${classes.expand}`]: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },

  [`& .${classes.expandOpen}`]: {
    transform: "rotate(180deg)",
  },

  [`& .${classes.title}`]: {
    flex: 1,
    cursor: "pointer",
  },
}));

const ls = new LocalStorage("header");

export default function CookieBanner() {
  const { t } = useTranslation("site");
  const [expanded, setExpanded] = useState(false);
  const [bannerOpen, setBannerOpen] = useState(
    !ls.getItem("cookie-banner-closed")
  );

  const handleExpandClick = (e) => {
    e.preventDefault();
    setExpanded((expanded) => !expanded);
    ReactGA.event({
      category: "User",
      action: "Expand cookie banner",
    });
  };

  const onCloseBanner = (e) => {
    e.preventDefault();
    setBannerOpen(false);
    ls.setItem("cookie-banner-closed", true);
    ReactGA.event({
      category: "User",
      action: "Close cookie banner",
    });
  };

  return (
    <NoSsr>
      <Collapse in={bannerOpen} timeout="auto" unmountOnExit>
        <StyledAppBar color="default" position="absolute">
          <Container maxWidth="md" disableGutters>
            <Toolbar>
              <IconButton
                edge="start"
                className={classNames(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                size="large"
              >
                <ExpandMoreIcon />
              </IconButton>

              <Typography
                variant="body2"
                color="textPrimary"
                className={classes.title}
                onClick={handleExpandClick}
              >
                {t("cookie_banner.title")}
              </Typography>

              <Button
                onClick={onCloseBanner}
                variant="contained"
                disableElevation
              >
                {t("cookie_banner.submit")}
              </Button>
            </Toolbar>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Toolbar>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {t("cookie_banner.text")}{" "}
                  <MuiLink
                    href="https://sportrussia.tv/document/privacy"
                    color="secondary"
                    rel="noreferer noopener"
                    underline="hover"
                  >
                    {t("cookie_banner.privacy_policy")}
                  </MuiLink>
                  .
                </Typography>
              </Toolbar>
            </Collapse>
          </Container>
        </StyledAppBar>
      </Collapse>
    </NoSsr>
  );
}
