import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import RecommendedContentsListItem from "site/components/RecommendedContentsListItem";

const PREFIX = "RecommendedContentsList";

const classes = {
  root: `${PREFIX}-root`,
  divider: `${PREFIX}-divider`,
};

const Root = styled("div")({
  [`&.${classes.root}`]: {
    width: "100%",
    // background: "blue",
    position: "absolute",
    top: 48,
    bottom: 0,
    left: 0,
    right: 0,
    overflowX: "hidden",
    overflowY: "auto",
    zIndex: 1,

    scrollBehavior: "smooth",

    msOverflowStyle: "none", // IE 10+
    scrollbarWidth: "none", // Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Safari and Chrome
    },
  },
  [`& .${classes.divider}`]: {
    backgroundColor: "#101010",
  },
});

function RecommendedContentsList({ nodes, contentId, onClickItem }) {
  return (
    <Root className={classes.root}>
      <List disablePadding>
        {nodes.map((content, index) => (
          <Fragment key={content.id}>
            {index > 0 && <Divider className={classes.divider} />}
            <RecommendedContentsListItem
              selected={contentId === content.id}
              content={content}
              onClickItem={onClickItem}
            />
          </Fragment>
        ))}
      </List>
    </Root>
  );
}

RecommendedContentsList.propTypes = {
  onClickItem: PropTypes.func.isRequired,
  contentId: PropTypes.string.isRequired,
  nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RecommendedContentsList;
