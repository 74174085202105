import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { SearchSuggestQuery } from "site/queries";

const getOptionLabel = (option) => {
  if (typeof option === "string") {
    return option;
  }

  if (typeof option === "object") {
    return option.title;
  }
};

export default function HeaderSearch({ searchParams, onClickCloseIcon }) {
  const { t } = useTranslation("site");
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");

  const { data } = useQuery(SearchSuggestQuery, {
    fetchPolicy: "cache",
    skip: !inputValue,
    variables: {
      query: inputValue,
    },
  });

  const onInputChange = (event, value) => {
    setInputValue(value);
  };

  const onChange = (event, value) => {
    if (!value) return;

    if (typeof value === "string") {
      ReactGA.event({
        category: "User",
        action: "Search",
        label: value,
      });

      searchParams.set("query", value);

      return navigate(`/search?${searchParams}`);
    }

    if (typeof value === "object") {
      ReactGA.event({
        category: "User",
        action: "Search arena",
        label: value.title,
      });

      return navigate(value.path);
    }
  };

  const suggestions = data?.search || [];

  return (
    <Autocomplete
      onChange={onChange}
      onInputChange={onInputChange}
      onBlur={onClickCloseIcon}
      freeSolo
      autoComplete
      clearOnEscape
      fullWidth
      options={suggestions}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            placeholder={t("search")}
            autoFocus
            fullWidth
          />
        );
      }}
    />
  );
}

HeaderSearch.propTypes = {
  searchParams: PropTypes.object.isRequired,
  onClickCloseIcon: PropTypes.func.isRequired,
};
