import React from "react";
import { styled } from "@mui/material/styles";
import usePageview from "core/hooks/usePageview";
import useScrollToTop from "core/hooks/useScrollToTop";
import ContentList from "site/components/ContentList";
import HomepageTitle from "site/components/HomepageTitle";
import { useTranslation } from "react-i18next";
import SearchHelmet from "site/components/SearchHelmet";
import { useSearchParams, useLocation } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 2),
  },
}));

export default function Search() {
  const { t } = useTranslation("site");
  const location = useLocation();

  usePageview(location.pathname, "Search");
  useScrollToTop();

  const [searchParams] = useSearchParams();
  const query = searchParams.get("query");

  return (
    <>
      <SearchHelmet query={query} />

      <Root>
        <HomepageTitle title={t("search_result", { query })} />
        <ContentList logo search={query} rowsPerPage={12} />
      </Root>
    </>
  );
}
