import moment from "core/utils/moment";

export default function jsonLd(content) {
  if (!content) return;

  const data = {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    name: content.title,
    description: content.description || content.rightholder.name,
    uploadDate: moment(content.publishAt).format(),
    publisher: {
      "@type": "Organization",
      name: content.rightholder.name,
    },
    embedUrl: content.url,
    interactionStatistic: {
      "@type": "InteractionCounter",
      interactionType: { "@type": "http://schema.org/WatchAction" },
      userInteractionCount: content.totalViews,
    },
  };

  if (content.preview) {
    data.thumbnailUrl = content.preview;
  }

  if (content.shareImages.length) {
    const shareImage =
      content.shareImages.find((i) => i.format === "MAXRES") ||
      content.shareImages.find((i) => i.format === "HIGHT") ||
      content.shareImages.find((i) => i.format === "MEDIUM");

    if (shareImage) {
      data.thumbnailUrl = shareImage.url;
    }
  }

  if (content.type === "VIDEO") {
    if (content.duration) {
      data.duration = moment
        .duration(content.duration, "seconds")
        .toISOString();
    }
  }

  if (content.type === "STREAM") {
    data.publication = [];

    if (content.startAt) {
      data.publication.push({
        "@type": "BroadcastEvent",
        isLiveBroadcast: true,
        startDate: moment(content.startAt).format(), // "2018-10-27T18:00:00+00:00",
        endDate: moment(content.startAt).add(2, "h").format(),
      });
    }

    if (content.streamData.startAt) {
      const endDate = content.streamData.finishAt
        ? moment(content.streamData.finishAt)
        : moment(content.streamData.startAt).add(2, "h");

      data.publication.push({
        "@type": "BroadcastEvent",
        isLiveBroadcast: true,
        startDate: moment(content.streamData.startAt).format(), // "2018-10-27T14:00:00+00:00",
        endDate: endDate.format(),
      });
    }
  }

  if (content.rightholder.logo) {
    data.publisher.logo = {
      "@type": "ImageObject",
      url: content.rightholder.logo.url,
    };
  }

  return JSON.stringify(data, null, 2);
}
