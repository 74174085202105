import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import RecommendedContentsList from "site/components/RecommendedContentsList";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import ContentPlayer from "site/components/ContentPlayer";

const PREFIX = "RecommendedContents";

const classes = {
  root: `${PREFIX}-root`,
  top: `${PREFIX}-top`,
  player: `${PREFIX}-player`,
  recommended: `${PREFIX}-recommended`,
  contents: `${PREFIX}-contents`,
  square: `${PREFIX}-square`,
  title: `${PREFIX}-title`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    padding: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },

  [`&.${classes.top}`]: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },

  [`& .${classes.player}`]: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2),
    },
  },

  [`& .${classes.recommended}`]: {
    flexShrink: 0,
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      width: SIDEBAR_WIDTH,
    },
  },

  [`& .${classes.contents}`]: {
    minHeight: 332,
    height: "100%",
    width: "100%",
    position: "relative",
    backgroundColor: "#1f1f1f",
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      borderRadius: 4,
    },
  },

  [`& .${classes.square}`]: {
    borderRadius: 0,
    [theme.breakpoints.up("md")]: {
      borderRadius: 0,
    },
  },

  [`& .${classes.title}`]: {
    fontWeight: 500,
  },
}));

const SIDEBAR_WIDTH = 345;

/**
 * Этот хук находит следующий по списку контент и дергает колбек,
 * чтобы компонент наверху знал куда переключать, когда текущее видео доиграет.
 */
function useNextContent({ setNextContentId, contentId, nodes }) {
  useEffect(() => {
    if (!nodes) return;

    const currentContentIndex = nodes.findIndex(
      (content) => content.id === contentId
    );

    const nextContent = nodes[currentContentIndex + 1] || nodes[0];

    if (nextContent) {
      if (setNextContentId) {
        setNextContentId(nextContent.id);
      }
    }
  }, [nodes, contentId, setNextContentId]);
}

const sponsorPlayerLogoPlaces = {
  VIDEO: "VIDEO_ON_SITE",
  STREAM: "STREAM_ON_SITE",
};

const sponsorPlayerButtonPlaces = {
  VIDEO: "VIDEO_ON_SITE",
  STREAM: "STREAM_ON_SITE",
};

const sponsorVideoPlaces = {
  VIDEO: "VIDEO_ON_SITE",
  STREAM: "STREAM_ON_SITE",
};

const sponsorChatMessagePlaces = {
  STREAM: "STREAM_ON_SITE",
};

const sponsorInStreamBannerPlaces = {
  VIDEO: "VIDEO_ON_SITE",
  STREAM: "STREAM_ON_SITE",
};

const sponsorCornerBannerPlaces = {
  VIDEO: "VIDEO_ON_SITE",
  STREAM: "STREAM_ON_SITE",
};

function RecommendedContents({
  contentId,
  nodes,
  setNextContentId,
  onClickItem,
  onEnded,
  square,
  loading,
}) {
  const { t } = useTranslation("site");

  useNextContent({
    nodes,
    contentId,
    setNextContentId,
  });

  let featured = nodes.find((content) => content.id === contentId);

  if (featured) {
    featured = {
      ...featured,
      access: {
        ...featured.access,
        token: featured.accessSite.token, // чтобы у пурчасы был type === site, а не preview
      },
    };
  }

  const sponsorPlayerLogoPlace = sponsorPlayerLogoPlaces[featured?.type];
  const sponsorPlayerButtonPlace = sponsorPlayerButtonPlaces[featured?.type];
  const sponsorVideoPlace = sponsorVideoPlaces[featured?.type];
  const sponsorChatMessagePlace = sponsorChatMessagePlaces[featured?.type];
  const sponsorInStreamBannerPlace =
    sponsorInStreamBannerPlaces[featured?.type];
  const sponsorCornerBannerPlace = sponsorCornerBannerPlaces[featured?.type];

  return (
    <Root className={classes.top}>
      <div className={classes.player} key={contentId}>
        <ContentPlayer
          sponsorCornerBannerPlace={sponsorCornerBannerPlace}
          sponsorInStreamBannerPlace={sponsorInStreamBannerPlace}
          sponsorPlayerLogoPlace={sponsorPlayerLogoPlace}
          sponsorPlayerButtonPlace={sponsorPlayerButtonPlace}
          sponsorVideoPlace={sponsorVideoPlace}
          sponsorChatMessagePlace={sponsorChatMessagePlace}
          onEnded={onEnded}
          content={featured}
          loading={loading}
          autoPlay
          muted
        />
      </div>

      <div className={classes.recommended}>
        <div
          className={classNames(classes.contents, {
            [classes.square]: square,
          })}
        >
          <AppBar
            color="default"
            position="absolute"
            className={classes.appBar}
          >
            <Toolbar variant="dense">
              <Typography variant="subtitle1" className={classes.title} noWrap>
                {t("recommendedContents.title")}
              </Typography>
            </Toolbar>
          </AppBar>

          <RecommendedContentsList
            nodes={nodes}
            contentId={contentId}
            onClickItem={onClickItem}
          />
        </div>
      </div>
    </Root>
  );
}

RecommendedContents.propTypes = {
  loading: PropTypes.bool,
  square: PropTypes.bool,
  contentId: PropTypes.string.isRequired,
  setNextContentId: PropTypes.func,
  onClickItem: PropTypes.func,
  onEnded: PropTypes.func,
  linkTo: PropTypes.string,
  nodes: PropTypes.array.isRequired,
};

export default RecommendedContents;
