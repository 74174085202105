import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { textFormat } from "core/utils/string";
import ShareButton from "site/components/ShareButton";
import Avatar from "@mui/material/Avatar";
import ReactGA from "react-ga4";

const PREFIX = "ChannelHeader";

const classes = {
  root: `${PREFIX}-root`,
  main: `${PREFIX}-main`,
  avatar: `${PREFIX}-avatar`,
  head: `${PREFIX}-head`,
  logo: `${PREFIX}-logo`,
  body: `${PREFIX}-body`,
  footer: `${PREFIX}-footer`,
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
  subscribe: `${PREFIX}-subscribe`,
  sport: `${PREFIX}-sport`,
  about: `${PREFIX}-about`,
  bodySkeleton: `${PREFIX}-bodySkeleton`,
  link: `${PREFIX}-link`,
  aboutButton: `${PREFIX}-aboutButton`,
  rightholderType: `${PREFIX}-rightholderType`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.main}`]: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },

  [`& .${classes.avatar}`]: {
    width: 100,
    height: 100,
  },

  [`& .${classes.head}`]: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "18px 16px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },

  [`& .${classes.logo}`]: {
    marginBottom: theme.spacing(2),
    marginRight: 0,
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
      marginRight: theme.spacing(2),
    },
  },

  [`& .${classes.body}`]: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },

  [`& .${classes.footer}`]: {
    padding: theme.spacing(0, 3, 3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
      marginLeft: "auto",
    },
  },

  [`& .${classes.buttons}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap",
    },
  },

  [`& .${classes.button}`]: {
    display: "flex",
    justifyContent: "center",
    "&:first-of-type": {
      marginLeft: 0,
      marginBottom: theme.spacing(2),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        marginBottom: 0,
        width: "auto",
      },
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(2),
    },
  },

  [`& .${classes.sport}`]: {
    display: "inline-block",
    marginTop: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },

  [`& .${classes.about}`]: {
    padding: theme.spacing(2, 2, 3),
  },

  [`& .${classes.bodySkeleton}`]: {
    margin: "auto",
    marginTop: 8,
    [theme.breakpoints.up("md")]: {
      margin: 0,
      marginTop: 8,
    },
  },

  [`& .${classes.link}`]: {
    color: "rgb(170, 170, 170)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textTransform: "capitalize",
  },

  [`& .${classes.aboutButton}`]: {
    color: "rgba(255, 255, 255, 0.3)",
  },

  [`& .${classes.rightholderType}`]: {
    marginLeft: theme.spacing(),
  },
}));

export default function ChannelHeader({ loading, rightholder }) {
  const { t } = useTranslation("site");

  if (loading && !rightholder) {
    return (
      <StyledCard className={classes.root} square>
        <div className={classes.main}>
          <div className={classes.head}>
            <div className={classes.logo}>
              <Skeleton variant="circular" width={100} height={100} />
            </div>

            <div className={classes.body}>
              <Skeleton
                variant="rectangular"
                width={320}
                height={40}
                style={{ margin: "auto" }}
              />

              <Skeleton
                variant="rectangular"
                width={160}
                height={16}
                className={classes.bodySkeleton}
              />

              <Skeleton
                variant="rectangular"
                width={100}
                height={16}
                className={classes.bodySkeleton}
              />
            </div>
          </div>

          <div className={classes.footer}>
            <div className={classes.buttons}>
              <Skeleton
                variant="rectangular"
                height={42}
                width={80}
                style={{ marginLeft: 8 }}
              />
            </div>
          </div>
        </div>
      </StyledCard>
    );
  }

  const links = [];

  if (rightholder?.url) {
    links.push({
      href: rightholder.url,
      text: "Website",
    });
  }

  if (rightholder?.links?.length) {
    links.push(...rightholder?.links);
  }

  const hasAbout =
    Boolean(rightholder.description) ||
    Boolean(links.length) ||
    Boolean(rightholder?.parents?.nodes.length);

  const onClickLink = (event) => {
    ReactGA.event({
      category: "User",
      action: "Click to link on channel page",
      label: event.target.innerText,
    });
  };

  const onClickParent = (event) => {
    ReactGA.event({
      category: "User",
      action: "Click to parent on channel page",
      label: event.target.innerText,
    });
  };

  return (
    <StyledCard className={classes.root} square>
      <div className={classes.main}>
        <div className={classes.head}>
          <div className={classes.logo}>
            <Avatar
              src={rightholder?.logo?.url}
              className={classes.avatar}
              alt={rightholder.name}
            >
              {rightholder.name[0]}
            </Avatar>
          </div>

          <div className={classes.body}>
            <Typography variant="h5" component="h1">
              {rightholder.name}
              {rightholder.type && (
                <Chip
                  label={rightholder.type.title}
                  variant="outlined"
                  className={classes.rightholderType}
                />
              )}
            </Typography>

            <Typography variant="body2" color="textSecondary">
              {rightholder?.sport?.title}
              {rightholder?.sport && <> &bull; </>}

              {t("joined", { date: rightholder.createdAt })}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t("totalViews", { count: rightholder.totalViews })}
            </Typography>
          </div>
        </div>

        <div className={classes.footer}>
          <div className={classes.buttons}>
            <div className={classes.button}>
              <ShareButton
                variant="outlined"
                shareUrlParams={{ rightholderId: rightholder.id }}
                shareUrl={rightholder.shareUrl}
                shareText={rightholder.name}
                shareDescription={rightholder.description}
                shareImage={rightholder.logo?.url}
              />
            </div>
          </div>
        </div>
      </div>

      <Divider sx={{ borderColor: "#111111" }} />

      {hasAbout && (
        <div className={classes.about}>
          <Grid container spacing={2} justifyContent="space-between">
            {rightholder.description && (
              <Grid item xs={12} sm={12}>
                <Typography
                  component="div"
                  variant="body2"
                  color="textSecondary"
                  className={classes.description}
                  dangerouslySetInnerHTML={{
                    __html: textFormat(rightholder.description),
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12}>
              <Grid container spacing={1}>
                {rightholder?.parents?.nodes.map((parent) => (
                  <Grid item key={parent.id}>
                    <Button
                      component={Link}
                      to={`/${parent.slug}`}
                      size="small"
                      color="inherit"
                      variant="outlined"
                      onClick={onClickParent}
                      className={classes.link}
                    >
                      {parent.name}
                    </Button>
                  </Grid>
                ))}
                {links.map((link, index) => (
                  <Grid item key={index}>
                    <Button
                      target="_blank"
                      rel="noopener nofollow"
                      href={link.href}
                      color="inherit"
                      size="small"
                      variant="outlined"
                      onClick={onClickLink}
                      className={classes.link}
                    >
                      {link.text}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </StyledCard>
  );
}

ChannelHeader.propTypes = {
  loading: PropTypes.bool,
  rightholder: PropTypes.shape({
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    totalViews: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    shareUrl: PropTypes.string.isRequired,
    description: PropTypes.string,
    type: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
    sport: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
    logo: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
    url: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })
    ),
    parents: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
        })
      ),
    }),

    children: PropTypes.shape({
      nodes: PropTypes.array,
    }),
  }),
};
