import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import CardHeader from "@mui/material/CardHeader";
import { Link } from "react-router-dom";

const PREFIX = "ChannelButton";

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
};

const StyledLink = styled(Link)({
  [`& .${classes.root}`]: {
    padding: 0,
  },
  [`& .${classes.title}`]: {
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

export default function ChannelButton({ rightholder }) {
  return (
    <StyledLink to={`/${rightholder.slug}`}>
      <CardHeader
        classes={classes}
        avatar={
          rightholder.logo ? (
            <Avatar src={rightholder.logo.url} alt={rightholder.name} />
          ) : (
            <Avatar>{rightholder.name[0]}</Avatar>
          )
        }
        title={rightholder.name}
        titleTypographyProps={{ color: "textPrimary" }}
      />
    </StyledLink>
  );
}

ChannelButton.propTypes = {
  rightholder: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    logo: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }),
};
