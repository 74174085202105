import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ContentCarousel from "site/components/ContentCarousel";

export default function RightholderParentContentListItem({
  rightholder,
  title,
}) {
  const nodes = rightholder?.contents?.nodes;

  if (!nodes || nodes.length === 0) return null;

  return (
    <Fragment>
      {title}
      <ContentCarousel nodes={nodes} />
    </Fragment>
  );
}

RightholderParentContentListItem.propTypes = {
  title: PropTypes.node,
  rightholder: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    contents: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
        })
      ),
    }),
  }),
};
