import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import { CardActionArea } from "@mui/material";
import { Link } from "react-router-dom";

const PREFIX = "RightholderLogoCarouselItem";

const classes = {
  root: `${PREFIX}-root`,
  actionArea: `${PREFIX}-actionArea`,
  avatar: `${PREFIX}-avatar`,
};

const StyledCard = styled(Card)(() => ({
  [`&.${classes.root}`]: {
    width: "100%",
  },

  [`& .${classes.actionArea}`]: {
    padding: 8,
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.avatar}`]: {
    width: 80,
    height: 80,
  },
}));

export default function RightholderLogoCarouselItem({ rightholder }) {
  return (
    <StyledCard className={classes.root}>
      <Tooltip title={rightholder.name}>
        <CardActionArea
          className={classes.actionArea}
          component={Link}
          to={`/${rightholder.slug}`}
        >
          {rightholder.logo ? (
            <Avatar src={rightholder.logo.url} className={classes.avatar} />
          ) : (
            <Avatar className={classes.avatar}>{rightholder.name[0]}</Avatar>
          )}
        </CardActionArea>
      </Tooltip>
    </StyledCard>
  );
}

RightholderLogoCarouselItem.propTypes = {
  loading: PropTypes.bool,
  rightholder: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    logo: PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  }),
};
