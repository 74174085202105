import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";
import SvgIcon from "@mui/material/SvgIcon";
import ReactGA from "react-ga4";
import getUserAgent from "core/utils/getUserAgent";

export default function ShareIcons({
  colors,
  small,
  shareUrl,
  shareText,
  shareImage,
}) {
  const { t } = useTranslation("site");
  const networks = [
    {
      title: t("shareButton.shareToFacebook"),
      name: t("shareButton.facebook"),
      color: "#3b5998",
      icon: (
        <path d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
      ),
      href: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        shareUrl
      )}`,
    },
    {
      title: t("shareButton.shareToTwitter"),
      name: t("shareButton.twitter"),
      color: "#1da1f2",
      icon: (
        <path d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" />
      ),
      href: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        shareText
      )}+${encodeURIComponent(shareUrl)}`,
    },
    {
      title: t("shareButton.shareToVkontakte"),
      name: t("shareButton.vkontakte"),
      color: "#4680c2",
      icon: (
        <path d="M20.8,7.74C20.93,7.32 20.8,7 20.18,7H18.16C17.64,7 17.41,7.27 17.28,7.57C17.28,7.57 16.25,10.08 14.79,11.72C14.31,12.19 14.1,12.34 13.84,12.34C13.71,12.34 13.5,12.19 13.5,11.76V7.74C13.5,7.23 13.38,7 12.95,7H9.76C9.44,7 9.25,7.24 9.25,7.47C9.25,7.95 10,8.07 10.05,9.44V12.42C10.05,13.08 9.93,13.2 9.68,13.2C9,13.2 7.32,10.67 6.33,7.79C6.13,7.23 5.94,7 5.42,7H3.39C2.82,7 2.7,7.27 2.7,7.57C2.7,8.11 3.39,10.77 5.9,14.29C7.57,16.7 9.93,18 12.08,18C13.37,18 13.53,17.71 13.53,17.21V15.39C13.53,14.82 13.65,14.7 14.06,14.7C14.36,14.7 14.87,14.85 16.07,16C17.45,17.38 17.67,18 18.45,18H20.47C21.05,18 21.34,17.71 21.18,17.14C21,16.57 20.34,15.74 19.47,14.76C19,14.21 18.29,13.61 18.07,13.3C17.77,12.92 17.86,12.75 18.07,12.4C18.07,12.4 20.54,8.93 20.8,7.74Z" />
      ),
      href: `https://vk.com/share.php?url=${encodeURIComponent(shareUrl)}`,
    },
    {
      title: t("shareButton.shareToOdnoklassniki"),
      name: t("shareButton.odnoklassniki"),
      color: "#f7931f",
      icon: (
        <path d="M17.83,12.74C17.55,12.17 16.76,11.69 15.71,12.5C14.28,13.64 12,13.64 12,13.64C12,13.64 9.72,13.64 8.29,12.5C7.24,11.69 6.45,12.17 6.17,12.74C5.67,13.74 6.23,14.23 7.5,15.04C8.59,15.74 10.08,16 11.04,16.1L10.24,16.9C9.1,18.03 8,19.12 7.25,19.88C6.8,20.34 6.8,21.07 7.25,21.5L7.39,21.66C7.84,22.11 8.58,22.11 9.03,21.66L12,18.68C13.15,19.81 14.24,20.9 15,21.66C15.45,22.11 16.18,22.11 16.64,21.66L16.77,21.5C17.23,21.07 17.23,20.34 16.77,19.88L13.79,16.9L13,16.09C13.95,16 15.42,15.73 16.5,15.04C17.77,14.23 18.33,13.74 17.83,12.74M12,4.57C13.38,4.57 14.5,5.69 14.5,7.06C14.5,8.44 13.38,9.55 12,9.55C10.62,9.55 9.5,8.44 9.5,7.06C9.5,5.69 10.62,4.57 12,4.57M12,12.12C14.8,12.12 17.06,9.86 17.06,7.06C17.06,4.27 14.8,2 12,2C9.2,2 6.94,4.27 6.94,7.06C6.94,9.86 9.2,12.12 12,12.12Z" />
      ),
      href: `https://connect.ok.ru/offer?url=${encodeURIComponent(
        shareUrl
      )}&title=${encodeURIComponent(shareText)}&imageUrl=${encodeURIComponent(
        shareImage
      )}`,
    },
    {
      title: t("shareButton.shareToTelegram"),
      name: t("shareButton.telegram"),
      color: "#009fda",
      icon: (
        <path d="M9.78,18.65L10.06,14.42L17.74,7.5C18.08,7.19 17.67,7.04 17.22,7.31L7.74,13.3L3.64,12C2.76,11.75 2.75,11.14 3.84,10.7L19.81,4.54C20.54,4.21 21.24,4.72 20.96,5.84L18.24,18.65C18.05,19.56 17.5,19.78 16.74,19.36L12.6,16.3L10.61,18.23C10.38,18.46 10.19,18.65 9.78,18.65Z" />
      ),
      href: `https://t.me/share/url?url=${encodeURIComponent(
        shareUrl
      )}&title=${encodeURIComponent(shareText)}`,
    },
    {
      title: t("shareButton.shareToWhatsApp"),
      name: t("shareButton.whatsApp"),
      color: "#2bd146",
      icon: (
        <path d="M16.75,13.96C17,14.09 17.16,14.16 17.21,14.26C17.27,14.37 17.25,14.87 17,15.44C16.8,16 15.76,16.54 15.3,16.56C14.84,16.58 14.83,16.92 12.34,15.83C9.85,14.74 8.35,12.08 8.23,11.91C8.11,11.74 7.27,10.53 7.31,9.3C7.36,8.08 8,7.5 8.26,7.26C8.5,7 8.77,6.97 8.94,7H9.41C9.56,7 9.77,6.94 9.96,7.45L10.65,9.32C10.71,9.45 10.75,9.6 10.66,9.76L10.39,10.17L10,10.59C9.88,10.71 9.74,10.84 9.88,11.09C10,11.35 10.5,12.18 11.2,12.87C12.11,13.75 12.91,14.04 13.15,14.17C13.39,14.31 13.54,14.29 13.69,14.13L14.5,13.19C14.69,12.94 14.85,13 15.08,13.08L16.75,13.96M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C10.03,22 8.2,21.43 6.65,20.45L2,22L3.55,17.35C2.57,15.8 2,13.97 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12C4,13.72 4.54,15.31 5.46,16.61L4.5,19.5L7.39,18.54C8.69,19.46 10.28,20 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z" />
      ),
      href: `https://api.whatsapp.com/send?text=${encodeURIComponent(
        shareUrl
      )}`,
    },
  ];

  const userAgent = process.browser && getUserAgent();

  const onClickLink = async (event) => {
    event.preventDefault();

    if (navigator.share && userAgent?.userDeviceType !== "Web") {
      const shareData = {
        title: shareText,
        url: shareUrl,
      };

      try {
        await navigator.share(shareData);
      } catch (err) {
        console.log(err);
      }
    } else {
      window.open(
        event.currentTarget.href,
        event.currentTarget.getAttribute("title"),
        "width=640,height=436,toolbar=0,status=0"
      );
    }

    ReactGA.event({
      category: "User",
      action: "Click to share icon",
      label: event.currentTarget.getAttribute("title"),
    });

    return false;
  };

  return (
    <Grid container spacing={small ? 1 : 4} justifyContent="center">
      {networks.map((n) => (
        <Grid item key={n.title}>
          <a href={n.href} onClick={onClickLink}>
            <Tooltip title={n.title} arrow>
              <Avatar
                style={{
                  backgroundColor: colors && n.color,
                  color: "white",
                  width: small ? 40 : 60,
                  height: small ? 40 : 60,
                }}
              >
                <SvgIcon fontSize={small ? "default" : "large"}>
                  {n.icon}
                </SvgIcon>
              </Avatar>
            </Tooltip>
          </a>
        </Grid>
      ))}
    </Grid>
  );
}

ShareIcons.propTypes = {
  colors: PropTypes.bool,
  small: PropTypes.bool,
  shareUrl: PropTypes.string.isRequired,
  shareText: PropTypes.string.isRequired,
  shareImage: PropTypes.string,
};
