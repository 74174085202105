import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ContentListItem from "site/components/ContentListItem";
import Previewable from "site/components/Previewable";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import ContentCarouselPrevButton from "site/components/ContentCarouselPrevButton";
import ContentCarouselNextButton from "site/components/ContentCarouselNextButton";
import SwipeableViews from "react-swipeable-views";
import chunk from "core/utils/chunk";

const PREFIX = "ContentCarousel";

const classes = {
  root: `${PREFIX}-root`,
  rootServer: `${PREFIX}-rootServer`,
  swipeableViews: `${PREFIX}-swipeableViews`,
  group: `${PREFIX}-group`,
  item: `${PREFIX}-item`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: "relative",
  },

  [`& .${classes.rootServer}`]: {},

  [`& .${classes.swipeableViews}`]: {
    margin: theme.spacing(-0.5, -1.5),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, -3),
      padding: theme.spacing(0, 1.5),
    },
  },

  [`& .${classes.group}`]: {
    overflow: "hidden",
    padding: theme.spacing(0.5, 1.5),
  },

  [`& .${classes.item}`]: {
    display: "none",

    "&:nth-of-type(1)": {
      display: "block",
    },

    "&:nth-of-type(2)": {
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },

    "&:nth-of-type(3)": {
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
    },

    "&:nth-of-type(4)": {
      [theme.breakpoints.up("lg")]: {
        display: "block",
      },
    },

    "&:nth-of-type(5)": {
      [theme.breakpoints.up("xl")]: {
        display: "block",
      },
    },
  },
}));

export default function ContentCarousel({ nodes, autoPlay }) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));
  const md = useMediaQuery(theme.breakpoints.only("md"));
  const lg = useMediaQuery(theme.breakpoints.only("lg"));
  const xl = useMediaQuery(theme.breakpoints.only("xl"));
  const isMobile = xs;

  const [index, setIndex] = useState(0);

  const onChangeIndex = (index) => {
    setIndex(index);
  };

  if (process.browser) {
    let groups = [];

    if (xs) {
      // показывааем по 1 в ряд
      groups = chunk(nodes, 1);
    } else if (sm) {
      // показывааем по 2 в ряд
      groups = chunk(nodes, 2);
    } else if (md) {
      // показывааем по 3 в ряд
      groups = chunk(nodes, 3);
    } else if (lg) {
      // показывааем по 4 в ряд
      groups = chunk(nodes, 4);
    } else if (xl) {
      // показывааем по 5 в ряд
      groups = chunk(nodes, 5);
    }

    const prevDisabled = index === 0;
    const nextDisabled = groups.length === index + 1;

    const onClickPrev = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setIndex((index) => index - 1 || 0);
    };

    const onClickNext = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setIndex((index) => {
        if (index + 1 >= groups.length) {
          return 0;
        }
        return index + 1;
      });
    };

    return (
      <Root className={classes.root}>
        <ContentCarouselPrevButton
          disabled={prevDisabled}
          onClick={onClickPrev}
        />

        <ContentCarouselNextButton
          disabled={nextDisabled}
          onClick={onClickNext}
        />

        <SwipeableViews
          disableLazyLoading={true}
          className={classes.swipeableViews}
          index={index}
          onChangeIndex={onChangeIndex}
        >
          {groups.map((group, index) => (
            <div className={classes.group} key={index}>
              <Grid container spacing={2}>
                {group.map((content) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    key={content.id}
                    className={classes.item}
                  >
                    <Previewable isMobile={isMobile}>
                      {(focused) => (
                        <ContentListItem
                          content={content}
                          play={focused && autoPlay}
                        />
                      )}
                    </Previewable>
                  </Grid>
                ))}
              </Grid>
            </div>
          ))}
        </SwipeableViews>
      </Root>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {nodes.map((content) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={2}
            key={content.id}
            className={classes.item}
          >
            <ContentListItem content={content} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

ContentCarousel.defaultProps = {
  autoPlay: true,
  nodes: [],
};

ContentCarousel.propTypes = {
  autoPlay: PropTypes.bool,
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
};
