const STREAM = "STREAM";
const STARTED = "STARTED";
const COLLECTION = "COLLECTION";

export default function findFeaturedContent(nodes) {
  return nodes.find((content) => {
    // if (content.premium) {
    //   if (!content.viewerPurchase) {
    //     return false;
    //   }
    // }

    if (content.type === STREAM) {
      if (content.streamData.status !== STARTED) {
        return false;
      }
    }

    if (content.type === COLLECTION) {
      return false;
    }

    return true;
  });
}
