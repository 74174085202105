import React from "react";
import PropTypes from "prop-types";
import MuiAvatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

const Root = styled(Link)(({ theme }) => ({
  borderRadius: "50%",
  padding: "3px",
  backgroundImage: `linear-gradient(180deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
}));

const StyledAvatar = styled(MuiAvatar)({
  border: "2px solid #0D0D0D",
  width: 60,
  height: 60,
  backgroundColor: "#0D0D0D",
});

export default function RightholderAvatar({ rightholder }) {
  return (
    <Tooltip title={rightholder.name}>
      <Root to={`/${rightholder.slug}`}>
        <StyledAvatar src={rightholder?.logo?.url}>
          {rightholder.name[0]}
        </StyledAvatar>
      </Root>
    </Tooltip>
  );
}

RightholderAvatar.propTypes = {
  rightholder: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    logo: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
};
