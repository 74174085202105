import React from "react";
import { useQuery } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorScreen from "core/components/ErrorScreen";
import NoMatchScreen from "site/components/NoMatchScreen";
import PageBody from "site/components/PageBody";
import { PageQuery } from "site/queries";
import { useParams } from "react-router-dom";
export default function Page() {
  const { slug } = useParams();
  const { data, loading, error } = useQuery(PageQuery, {
    variables: {
      slug,
    },
  });

  const page = data?.page;

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!page && !loading) {
    return <NoMatchScreen />;
  }

  return <PageBody page={page} />;
}
