import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Header from "site/components/Header";

const Main = styled("main")(({ theme }) => ({
  paddingTop: 56,
  [theme.breakpoints.up("sm")]: {
    paddingTop: 64,
  },
}));

export default function AppShell({ children }) {
  return (
    <>
      <Main>{children}</Main>
      <Header />
    </>
  );
}

AppShell.propTypes = {
  children: PropTypes.node,
};
