import React from "react";
import { useLocation } from "react-router-dom";
import usePageview from "core/hooks/usePageview";
import useScrollToTop from "core/hooks/useScrollToTop";
import ExploreHelmet from "./ExploreHelmet";
import Feed from "site/components/Feed";
import NoSsr from "@mui/material/NoSsr";

export default function Explore() {
  const location = useLocation();

  usePageview(location.pathname, "Homepage");
  useScrollToTop();

  return (
    <>
      <ExploreHelmet />

      <NoSsr>
        <Feed />
      </NoSsr>
    </>
  );
}
