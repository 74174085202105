import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import Skeleton from "@mui/material/Skeleton";

const PREFIX = "HomepageTitle";

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    overflow: "hidden",
    margin: 0,
    padding: theme.spacing(5, 2, 1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5, 0, 1),
    },
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
  },

  [`& .${classes.title}`]: {
    fontWeight: 600,
    padding: theme.spacing(1, 0, 1),
    marginRight: theme.spacing(0.5),
  },
}));

export default function HomepageTitle({
  variant,
  loading,
  title,
  className,
  action,
}) {
  return (
    <Root className={classNames(classes.root, className)}>
      {loading ? (
        <Typography variant={variant} component="h2" noWrap>
          <Skeleton variant="text" width={300} height={48} />
        </Typography>
      ) : (
        <>
          <Typography
            variant={variant}
            component="h2"
            noWrap
            className={classes.title}
          >
            {title}
          </Typography>
          {action}
        </>
      )}
    </Root>
  );
}

HomepageTitle.defaultProps = {
  variant: "h5",
};

HomepageTitle.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  moreUrl: PropTypes.string,
  action: PropTypes.node,
  loading: PropTypes.bool,
};
