import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { FeaturedContentsQuery } from "site/queries";
import { useQuery } from "@apollo/client";
import RecommendedContents from "site/components/RecommendedContents";
import findFeaturedContent from "site/utils/findFeaturedContent";
import classNames from "classnames";

const PREFIX = "ChannelFeaturedContent";

const classes = {
  root: `${PREFIX}-root`,
  liga: `${PREFIX}-liga`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(2, 0, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2, 2, 0),
    },
  },

  [`&.${classes.liga}`]: {
    overflow: "hidden",
    padding: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 2),
    },
  },
}));

const VIDEO = "VIDEO";
const STREAM = "STREAM";

export default function ChannelFeaturedContent({ limit, slug, liga }) {
  const [empty, setEmpty] = useState(false);
  const [nextContentId, setNextContentId] = useState();
  const [contentId, setContentId] = useState();

  const { data, loading, error } = useQuery(FeaturedContentsQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      rightholderSlug: slug,
      limit,
      types: [VIDEO, STREAM],
    },
    onCompleted: (data) => {
      if (data?.featuredContents?.nodes) {
        const featured = findFeaturedContent(data.featuredContents.nodes);

        if (setEmpty) {
          setEmpty(data.featuredContents.nodes.length === 0);
        }

        if (featured) {
          setContentId(featured.id);
        }
      }
    },
  });

  if (error) return null;

  const nodes = data?.featuredContents?.nodes || [];

  if (nodes.length === 0) return null;
  if (!contentId) return null;

  const onEnded = () => {
    setContentId(nextContentId);
  };

  const onClickItem = (content) => {
    setContentId(content.id);
  };

  if (empty) {
    return null;
  }

  return (
    <Root
      className={classNames({
        [classes.root]: true,
        [classes.liga]: liga,
      })}
    >
      <RecommendedContents
        onEnded={onEnded}
        contentId={contentId}
        setNextContentId={setNextContentId}
        onClickItem={onClickItem}
        nodes={nodes}
        loading={loading}
      />
    </Root>
  );
}

ChannelFeaturedContent.propTypes = {
  limit: PropTypes.number,
  slug: PropTypes.string.isRequired,
  setEmpty: PropTypes.func,
  liga: PropTypes.bool,
};

ChannelFeaturedContent.defaultProps = {
  limit: 12,
};
