import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

const ButtonText = styled("span")({
  marginLeft: 1,
});

export default function HistoryButton({ versions, date, onChange }) {
  const [t] = useTranslation("site");

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={t("document.history")}>
        <Button onClick={handleClick}>
          <ButtonText>{t("document.date", { date })}</ButtonText>
          <KeyboardArrowDownIcon />
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {versions.map((v) => (
          <MenuItem
            key={v}
            selected={v === date}
            onClick={() => {
              onChange(v);
              handleClose();
            }}
          >
            {t("document.date", { date: v })}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

HistoryButton.propTypes = {
  versions: PropTypes.arrayOf(PropTypes.string).isRequired,
  date: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};
