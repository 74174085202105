import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import {
  durationFormat,
  formatDurationToSeconds,
  formatStringToHours,
} from "core/utils/string";

const PREFIX = "VideoTimeCheckbox";

const classes = {
  startTime: `${PREFIX}-startTime`,
  block: `${PREFIX}-block`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.startTime}`]: {
    width: 70,
  },

  [`&.${classes.block}`]: {
    marginLeft: 4,
  },
}));

export default function VideoTimeCheckbox({
  setStartTime,
  setStartFrom,
  startFrom,
  currentTime,
}) {
  const { t } = useTranslation("core");
  const [startTimeFormatted, setStartTimeFormatted] = useState("");

  const [playerTime, setPlayerTime] = useState(0);

  const onChangeStartTimeBlur = useCallback(() => {
    setStartTime(formatDurationToSeconds(startTimeFormatted));

    setStartTimeFormatted(formatStringToHours(startTimeFormatted));
  }, [setStartTime, startTimeFormatted]);

  const onKeyPressStartTime = useCallback(
    (event) => {
      if (event.key === "Enter") {
        onChangeStartTimeBlur();
      }
    },
    [onChangeStartTimeBlur]
  );

  const updateSeconds = useCallback(
    (seconds) => {
      setPlayerTime(seconds);

      const formattedTruncatedSeconds = durationFormat(seconds);
      setStartTimeFormatted(formattedTruncatedSeconds);
      setStartTime(formatDurationToSeconds(formattedTruncatedSeconds));
    },
    [setStartTime]
  );

  useEffect(() => {
    if (currentTime >= 0) {
      updateSeconds(currentTime);
    }
  }, [currentTime, playerTime, updateSeconds]);

  const onClickStartFrom = () => {
    setStartFrom(!startFrom);
  };

  const onChangeStartTime = (e) => {
    ReactGA.event({
      category: "Platform",
      action: "Click on Start Time checkbox",
    });
    setStartTimeFormatted(e.target.value);
  };

  return (
    <StyledGrid container alignItems="center" className={classes.block}>
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={startFrom}
              onChange={onClickStartFrom}
            />
          }
          label={t("videoTimeCheckbox.startFrom")}
        />
      </Grid>
      <Grid item>
        <TextField
          size="small"
          variant="outlined"
          onKeyPress={onKeyPressStartTime}
          disabled={!startFrom}
          className={classes.startTime}
          onChange={onChangeStartTime}
          value={startTimeFormatted}
          onBlur={onChangeStartTimeBlur}
        />
      </Grid>
    </StyledGrid>
  );
}

VideoTimeCheckbox.propTypes = {
  setStartTime: PropTypes.func,
  setStartFrom: PropTypes.func,
  startFrom: PropTypes.bool,
  currentTime: PropTypes.number,
};

VideoTimeCheckbox.defaultProps = {
  currentTime: 0,
};
