import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Player from "core/components/Player";
import { ContentQuery } from "player/queries";
import { useQuery } from "@apollo/client";
import { useNavigate, useLocation } from "react-router-dom";

import Logo from "site/components/Logo";

const EMBED = "EMBED";
const VIDEO_IN_EMBED = "VIDEO_IN_EMBED";

export default function DebugAdv() {
  // const contentId = "_ZCBxKm7NboDhpdn72SHwQ"; // development anton
  // const platformId = "Sv6Euh9nxeY3fWdXjr9LXg";
  const contentId = "bFhLsSguQgvCIAIX4hkH5w";
  const platformId = "xwRmSf_bjV1ItTgJoa-bTQ";

  const location = useLocation();
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const inputValue = decodeURIComponent(searchParams.get("inputValue") || "");

  useEffect(() => {
    function receiveMessage(event) {
      if (event.data.creative !== "SponsorVideo") {
        return;
      }
      if (event.data.place !== "PREROLL_IN_VIDEO_IN_EMBED") {
        return;
      }

      setEvents((events) => events.concat(event.data));
    }

    window.addEventListener("message", receiveMessage);

    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, []);

  const { data, loading, error } = useQuery(ContentQuery, {
    variables: {
      platformId,
      contentId,
      source: EMBED,
    },
  });

  const content = data?.player;

  const onChangeInput = (event) => {
    setEvents([]);
    navigate(`/debug/adv?inputValue=${encodeURIComponent(event.target.value)}`);
  };

  return (
    <Container>
      <Card>
        <AppBar position="static" color="default" elevation={2}>
          <Toolbar>
            <Logo width="200" style={{ margin: "auto" }} />
          </Toolbar>
        </AppBar>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                type="url"
                label="VAST URL"
                fullWidth
                value={inputValue}
                onChange={onChangeInput}
              />
            </Grid>
            {inputValue && (
              <>
                <Grid item xs={12} sm={8}>
                  {loading && "loading"}
                  {error && "error"}
                  {content && (
                    <Box
                      id="embed"
                      sx={{
                        overflow: "hidden",
                        width: "100%",
                        height: 0,
                        paddingTop: "56.25%",
                        position: "relative",
                      }}
                    >
                      <Player
                        key={inputValue}
                        content={content}
                        platformId={platformId}
                        fullscreenTarget="embed"
                        sponsorVideoPlace={VIDEO_IN_EMBED}
                        hideRelatedContent
                        dubugVastUrl={inputValue}
                      />
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>eventName</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {events.map((event, index) => (
                        <TableRow key={index}>
                          <TableCell>{event.eventName}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}
