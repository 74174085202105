import React from "react";
import PropTypes from "prop-types";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import Stack from "@mui/material/Stack";
import RightholderAvatar from "site/components/RightholderAvatar";
import { styled } from "@mui/material/styles";

export const RightholdersQuery = loader("./RightholdersQuery.graphql");

const Root = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-start",
  overflowX: "auto",
  overflowY: "hidden",
  msOverflowStyle: "none", // IE 10+
  scrollbarWidth: "none", // Firefox
  "&::-webkit-scrollbar": {
    display: "none", // Safari and Chrome
  },
});

export default function Rightholders({ dateFrom, dateTo, sportId }) {
  const { data } = useQuery(RightholdersQuery, {
    variables: {
      limit: 30,
      dateFrom,
      dateTo,
      sportId,
      sortType: "VIEWS_COUNT",
    },
  });

  const nodes = data?.topRightholders?.nodes;

  if (!nodes) return null;

  return (
    <Root>
      <Stack
        direction="row"
        spacing={1}
        // sx={{ width: "100%", justifyContent: "center" }}
      >
        {nodes.map((rightholder) => (
          <RightholderAvatar rightholder={rightholder} key={rightholder.id} />
        ))}
      </Stack>
    </Root>
  );
}

Rightholders.propTypes = {
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  sportId: PropTypes.string,
};
