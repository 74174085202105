import React from "react";
import PropTypes from "prop-types";
import HomepageTitle from "site/components/HomepageTitle";
import RightholderParentContentListItem from "site/components/RightholderParentContentListItem";
import { RightholderParentContentsQuery } from "site/queries";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

export default function RightholderParentContentList({
  rightholderSlug,
  type,
}) {
  const { t } = useTranslation("site");

  const { data } = useQuery(RightholderParentContentsQuery, {
    fetchPolicy: "cache-and-network",
    // ssr: false,
    variables: {
      type,
      rightholderSlug,
    },
  });

  const nodes = data?.rightholder?.parents?.nodes;
  const name = data?.rightholder?.name;

  if (!nodes || nodes.length === 0) {
    return null;
  }

  return nodes.map((rightholder) => (
    <RightholderParentContentListItem
      key={rightholder.id}
      rightholder={rightholder}
      title={
        <HomepageTitle
          title={t("rightholder_parent_content_item", {
            from: name,
            to: rightholder.name,
          })}
        />
      }
    />
  ));
}

const VIDEO = "VIDEO";
const STREAM = "STREAM";
const COLLECTION = "COLLECTION";

RightholderParentContentList.propTypes = {
  type: PropTypes.oneOf([VIDEO, STREAM, COLLECTION]),
  title: PropTypes.node,
  rightholderSlug: PropTypes.string.isRequired,
};
