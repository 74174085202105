import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import BaseHelmet from "site/components/BaseHelmet";
import ChannelHeader from "site/components/ChannelHeader";
import ContentList from "site/components/ContentList";
import TopContentList from "site/components/TopContentList";
import TrendingContentList from "site/components/TrendingContentList";
import ChannelFeaturedContent from "site/components/ChannelFeaturedContent";
import useScrollToTop from "core/hooks/useScrollToTop";
import ReactGA from "react-ga4";
import { RightholderQuery } from "site/queries";
import { useQuery } from "@apollo/client";
import ChannelBanner from "site/components/ChannelBanner";
import ChannelTitle from "site/components/ChannelTitle";
import RightholderParentContentList from "site/components/RightholderParentContentList";
import RightholderLogoCarousel from "site/components/RightholderLogoCarousel";
import RetargetPixels from "core/components/RetargetPixels";
import ErrorScreen from "core/components/ErrorScreen";
import NoMatchScreen from "site/components/NoMatchScreen";
import SponsorTopBanner from "site/components/SponsorTopBanner";
import usePreviewBanner from "core/hooks/usePreviewBanner";
import { useLocation, useParams } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 2),
  },
}));

const VIDEO = "VIDEO";
const COLLECTION = "COLLECTION";
const ARENA = "ARENA";
const SPONSOR_TOP_BANNER_PREVIEW_URL =
  "https://st.spads.me/image/uev1Db0mX.png";

export default function Channel() {
  const location = useLocation();
  const { slug } = useParams();
  const previewBanner = usePreviewBanner(
    "sponsorTopBannerPreview",
    SPONSOR_TOP_BANNER_PREVIEW_URL
  );

  const onCompleted = (data) => {
    if (data.rightholder) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: data.rightholder.name,
      });
    }
  };

  const { data, loading, error } = useQuery(RightholderQuery, {
    fetchPolicy: "cache-and-network",
    variables: { slug },
    onCompleted,
  });

  useScrollToTop();

  const rightholder = data?.rightholder;

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!rightholder && !loading) {
    return <NoMatchScreen />;
  }

  return (
    <Fragment key={slug}>
      {rightholder && (
        <BaseHelmet
          logo={rightholder.logo}
          title={rightholder.name}
          description={rightholder.description}
          oembedUrl={rightholder.shareUrl}
          canonicalUrl={rightholder.shareUrl}
        />
      )}

      {rightholder && (
        <SponsorTopBanner
          rightholderId={rightholder?.id}
          place={ARENA}
          previewBanner={previewBanner}
        >
          <ChannelBanner rightholder={rightholder} />
        </SponsorTopBanner>
      )}

      {rightholder && (
        <RetargetPixels
          rightholder={rightholder.id}
          sport={rightholder.sport?.id}
          eventType="showChannel"
        />
      )}

      <ChannelHeader loading={loading} rightholder={rightholder} />

      {rightholder && (
        <RightholderLogoCarousel nodes={rightholder.children.nodes} />
      )}

      <ChannelFeaturedContent
        liga={rightholder?.children?.nodes?.length > 0}
        slug={slug}
        limit={10}
      />

      <Root>
        <TrendingContentList
          rightholderSlug={slug}
          limit={12}
          type={VIDEO}
          title={<ChannelTitle slug={slug} title="trending_channel_videos" />}
        />

        <TopContentList
          rightholderSlug={slug}
          limit={12}
          type={VIDEO}
          title={<ChannelTitle slug={slug} title="best_channel_videos" />}
        />

        <RightholderParentContentList rightholderSlug={slug} />

        <ContentList
          title={<ChannelTitle slug={slug} title="latest_channel_videos" />}
          rightholderSlug={slug}
          rowsPerPage={12}
          types={[VIDEO, COLLECTION]}
        />
      </Root>
    </Fragment>
  );
}
