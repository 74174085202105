import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { SponsorTopBannerQuery } from "site/queries";
import { useQuery } from "@apollo/client";
import {
  createSponsorImpression,
  createSponsorClick,
  trackAdPixel,
} from "core/utils/tracker";
import ButtonBase from "@mui/material/ButtonBase";
import { getThumbnailSrcSet } from "core/utils/getSrcSet";

const Root = styled("div")({
  width: "100%",
  display: "block",
});

const StyledButton = styled(ButtonBase)({
  width: "100%",
  display: "block",
});

const StyledImage = styled("img")({
  width: "100%",
  display: "block",
  margin: 0,
});

const ARENA = "ARENA";
const STREAM = "STREAM";
const VIDEO = "VIDEO";
const COLLECTION = "COLLECTION";
const IMPRESSION = "IMPRESSION";
const MAXRES = "MAXRES";

export default function SponsorTopBanner({
  place,
  contentId,

  rightholderId,
  children,
  previewBanner,
}) {
  const { data, loading } = useQuery(SponsorTopBannerQuery, {
    skip: !process.browser || !!previewBanner,
    fetchPolicy: "network-only",
    variables: {
      contentId,
      rightholderId,
      topBannerPlace: place,
    },
  });

  if (previewBanner) {
    return (
      <Root>
        <StyledImage src={previewBanner} alt={place} />
      </Root>
    );
  }

  if (loading) return null;
  if (!data?.sponsorCampaign) return children;

  const {
    id,
    link,
    token,
    thumbnails,
    isClickable,
    impressionPixel,
    clickPixel,
  } = data.sponsorCampaign.topBanner;

  const onClick = () => {
    if (isClickable) {
      if (clickPixel) {
        trackAdPixel(clickPixel);
      }

      createSponsorClick(token);
    }
  };

  const onLoad = () => {
    createSponsorImpression(token, "Top Banner", IMPRESSION);

    if (impressionPixel) {
      trackAdPixel(impressionPixel);
    }
  };

  const srcSet = getThumbnailSrcSet(thumbnails);

  const src = thumbnails?.find((t) => t.format === MAXRES)?.url;

  return (
    <StyledButton
      onClick={onClick}
      href={isClickable ? link : ""}
      target="_blank"
      fullWidth
      key={id}
    >
      <StyledImage src={src} srcSet={srcSet} alt={place} onLoad={onLoad} />
    </StyledButton>
  );
}

SponsorTopBanner.defaultProps = {
  children: null,
};

SponsorTopBanner.propTypes = {
  place: PropTypes.oneOf([ARENA, STREAM, VIDEO, COLLECTION]).isRequired,
  previewBanner: PropTypes.string,
  contentId: PropTypes.string,
  rightholderId: PropTypes.string,
  children: PropTypes.node,
};
